import GRP200229014 from "../VisaForms/GRP200229014.pdf";
import INZ1000 from "../VisaForms/INZ1000.pdf";
import INZ1012 from "../VisaForms/INZ1012.pdf";
import INZ1014 from "../VisaForms/INZ1014.pdf";
import INZ1015 from "../VisaForms/INZ1015.pdf";
import INZ1015Online from "../VisaForms/INZ1015.pdf";
import INZ1017 from "../VisaForms/INZ1017.pdf";
import INZ1020 from "../VisaForms/INZ1020.pdf";
import INZ1023 from "../VisaForms/INZ1023.pdf";
import INZ1024 from "../VisaForms/INZ1024.pdf";
import INZ1025 from "../VisaForms/INZ1025.pdf";
import INZ1027 from "../VisaForms/INZ1027.pdf";
import INZ1056 from "../VisaForms/INZ1056.pdf";
import INZ1090 from "../VisaForms/INZ1090.pdf";
import INZ1100 from "../VisaForms/INZ1100.pdf";
import INZ1103 from "../VisaForms/INZ1103.pdf";
import INZ1111 from "../VisaForms/INZ1111.pdf";
import INZ1112 from "../VisaForms/INZ1112.pdf";
import INZ1113 from "../VisaForms/INZ1113.pdf";
import INZ1146 from "../VisaForms/INZ1146.pdf";
import INZ1137 from "../VisaForms/INZ1137.pdf";
import INZ1160 from "../VisaForms/INZ1160.pdf";
import INZ1163 from "../VisaForms/INZ1163.pdf";
import INZ1165 from "../VisaForms/INZ1165.pdf";
import INZ1166 from "../VisaForms/INZ1166.pdf";
import INZ1169 from "../VisaForms/INZ1169.pdf";
import INZ1170 from "../VisaForms/INZ1170.pdf";
import INZ1175 from "../VisaForms/INZ1175.pdf";
import INZ1178 from "../VisaForms/INZ1178.pdf";
import INZ1183 from "../VisaForms/INZ1183.pdf";
import INZ1190 from "../VisaForms/INZ1190.pdf";
import INZ1198 from "../VisaForms/INZ1198.pdf";
import INZ1200 from "../VisaForms/INZ1200.pdf";
import INZ1202 from "../VisaForms/INZ1202.pdf";
import INZ1206 from "../VisaForms/INZ1206.pdf";
import INZ1209 from "../VisaForms/INZ1209.pdf";
import INZ1222 from "../VisaForms/INZ1222.pdf";
import INZ1224 from "../VisaForms/INZ1224.pdf";
import INZ1225 from "../VisaForms/INZ1225.pdf";
import INZ1226 from "../VisaForms/INZ1226.pdf";
import INZ1235 from "../VisaForms/INZ1235.pdf";
import INZ1241 from "../VisaForms/INZ1241.pdf";
import INZ1242 from "../VisaForms/INZ1242.pdf";
import INZ1243 from "../VisaForms/INZ1243.pdf";
import INZ1249 from "../VisaForms/INZ1249.pdf";
import INZ1252 from "../VisaForms/INZ1252.pdf";
import INZ1256 from "../VisaForms/INZ1256.pdf";
import INZ1263 from "../VisaForms/INZ1263.pdf";
import INZ1279 from "../VisaForms/INZ1279.pdf";
import INZ1370 from "../VisaForms/INZ1370.pdf";
import INZ1377 from "../VisaForms/INZ1377.pdf";
import INZ1385 from "../VisaForms/INZ1385.pdf";
import PCA200228980 from "../images/PCA200228980.pdf";
import SVA200228992 from "../images/SVA200228992.pdf";
import VVA200229003 from "../images/VVA200229003.pdf";
import WVA200229010 from "../images/WVA200229010.pdf";
// Change Name

export const visaForms = [
  {
    title: "INZ1000 - RESIDENCE APPLICATION",
    link: INZ1000,
  },
  {
    title: "INZ1012 - STUDENT VISA APPLICATION",
    link: INZ1012,
  },
  {
    title: "INZ1014 - FINANCIAL UNDERTAKING FOR STUDENT",
    link: INZ1014,
  },
  {
    title: "INZ1015 - WORK VISA APPLICATION",
    link: INZ1015,
  },
  {
    title: "INZ1015 - WORK VISA APPLICATION - ONLINE",
    link: INZ1015Online,
  },
  {
    title: "INZ1017 - VISITOR VISA APPLICATION",
    link: INZ1017,
  },
  {
    title: " INZ1020 - APPLICATION FOR A VARIATION OF CONDITION",
    link: INZ1020,
  },
  {
    title: " INZ1137 - REQUEST FOR STATEMENT OF IMMIGRATION STATUS",
    link: INZ1137,
  },
  {
    title: "INZ1023 - APPLICATION TRANSFER OR CONFIRMATION OF A VISA",
    link: INZ1023,
  },
  {
    title: "INZ1024 - SPONSORSHIP FORM FOR RESIDENCE",
    link: INZ1024,
  },
  {
    title: "INZ1025 - SPONSORSHIP FORM FOR VISITING NZ",
    link: INZ1025,
  },
  {
    title:
      "INZ1027 - SUPPLEMENTARY FORM FOR CHINESE VISITORS, WORKERS AND STUDENTS",
    link: INZ1027,
  },
  {
    title: "INZ1056 - ENTREPRENEUR RESIDENCE",
    link: INZ1056,
  },
  {
    title: "INZ1090 - EMPLOYER ACCREDITATION APPLICATION",
    link: INZ1090,
  },
  {
    title: "INZ1100 - EXPRESSION OF INTEREST FORM",
    link: INZ1100,
  },
  {
    title: "INZ1103 - EXPRESSION OF INTEREST FORM CHILD SUPPLEMENT",
    link: INZ1103,
  },
  {
    title: "INZ1111 - VISITOR VISA APPLICATION FOR AUCKLAND REGION APPLICANTS",
    link: INZ1111,
  },
  {
    title: "INZ1112 - REQUEST FOR APPROVAL IN PRINCIPLE",
    link: INZ1112,
  },
  {
    title: "INZ1113 - EMPLOYER SUPPLEMENTARY FORM",
    link: INZ1113,
  },
  {
    title:
      "INZ1146 - FORM FOR PARTNERS SUPPORTING PARTNERSHIP-BASED TEMPORARY ENTRY APPLICATIONS",
    link: INZ1146,
  },
  {
    title: "INZ1160 - IMMIGRATION ADVISOR DETAIL",
    link: INZ1160,
  },
  {
    title:
      "INZ1163 - INVENTOR PLUS (INVESTOR 1 CATEGORY) APPLICATION FOR RESIDENCE",
    link: INZ1163,
  },
  {
    title: "INZ1165 - INVESTOR (INVESTOR 2 CATEGORY) EXPRESSION OF INTEREST",
    link: INZ1165,
  },
  {
    title: "INZ1166 - INVESTOR (INVESTOR 2 CATEGORY) APPLICATION FOR RESIDENCE",
    link: INZ1166,
  },
  {
    title: "INZ1169 - ENTREPRENEUR PLUS CATEGORY APPLICATION FOR RESIDENCE",
    link: INZ1169,
  },
  {
    title: "INZ1170 - PARENT RETIREMENT CATEGORY RESIDENCE APPLICATION",
    link: INZ1170,
  },
  {
    title:
      "INZ1175 - APPLICATION FROM A RESIDENT OR FORMER RESIDENT VISA HOLDER",
    link: INZ1175,
  },
  {
    title: "INZ1178 - PARTNERSHIP SUPPORT FORM FOR RESIDENCE",
    link: INZ1178,
  },
  {
    title: "INZ1183 -  CUSTOMER REFUND REQUEST FORM (ONSHORE)",
    link: INZ1183,
  },
  {
    title: "INZ1190 - SPONSORSHIP FORM FOR RELIGIOUS WORKERS",
    link: INZ1190,
  },
  {
    title: "INZ1198 - PARTNERSHIP BASED TEMPORARY VISA APPLICATION",
    link: INZ1198,
  },
  {
    title: "INZ1200 - ADDITIONAL INFORMATION FORM",
    link: INZ1200,
  },
  {
    title: "INZ1202 - PARENT CATEGORY EXPRESSION OF INTEREST FORM",
    link: INZ1202,
  },
  {
    title: "INZ1206 - PARENT CATEGORY RESIDENCE APPLICATION",
    link: INZ1206,
  },
  {
    title: "INZ1209 - NATIONAL SECURITY CHECK FORM",
    link: INZ1209,
  },
  {
    title: "INZ1222 - ENTREPRENEUR WORK VISA APPLICATION",
    link: INZ1222,
  },
  {
    title: "INZ1224 - VISITOR VISA DECLARATION FORM",
    link: INZ1224,
  },
  {
    title: "INZ1225 - WORK VISA DECLARATION FORM",
    link: INZ1225,
  },
  {
    title: "INZ1226 - STUDENT VISA DECLARATION FORM",
    link: INZ1226,
  },
  {
    title: "INZ1235 - EMPLOYER SUPPLEMENTARY FORM SKILLED MIGRANT CATEGORY",
    link: INZ1235,
  },
  {
    title: "INZ1241 - PARTNER AND CHILD TEMPORARY VISA DECLARATION FORM",
    link: INZ1241,
  },
  {
    title: "INZ1242 - PARTNER AND CHILD RESIDENT VISA DECLARATION FORM",
    link: INZ1242,
  },
  {
    title:
      "INZ1243 - APPLICATION FOR A VARIATION OF CONDITIONS FOR HOLDERS OF A POST-STUDY WORK VISA - EMPLOYER ASSISTED",
    link: INZ1243,
  },
  {
    title: "INZ1249 - PARENT CATEGORY EXPRESSION OF INTEREST UPDATE FORM",
    link: INZ1249,
  },
  {
    title: "INZ1252 - MANAGED ISOLATION AND QUARANTINE (MIQ)",
    link: INZ1252,
  }, // changes
  {
    title: "INZ1256 - Sponsor or Supporting Partner Declaration Form",
    link: INZ1256,
  },
  {
    title: "INZ1263 - APPROVAL FOR A CRITICAL WORKER REQUEST FORM",
    link: INZ1263,
  },
  {
    title: "INZ1279 - EMPLOYER ACCREDITATION DECLARATION FORM",
    link: INZ1279,
  },
  {
    title: "INZ1370 - MIGRANT EXPLOITATION PRETECTION WORK VISA",
    link: INZ1370,
  },
  {
    title: "INZ1377 - RECOVERY VISA",
    link: INZ1377,
  },
  {
    title: "INZ1385 - ADDITIONAL FORM FOR THE ACCREDITED EMPLOYER WORK VISA",
    link: INZ1385,
  },
  {
    title: "GROUP APPLICATION - G117767",
    link: GRP200229014,
  },
  {
    title: "PARTNER OR CHILD VISA APPLICATION  - A2192076",
    link: PCA200228980,
  },
  {
    title: "STUDENT VISA APPLICATION  - A2192087",
    link: SVA200228992,
  },
  {
    title: "VISITOR VISA APPLICATION  - A2192098",
    link: VVA200229003,
  },
  {
    title: "WORK VISA APPLICATION - A2192104",
    link: WVA200229010,
  },
];
