import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import {
  Form,
  Input,
  Button,
  Select,
  message,
  Upload,
  Spin,
  Modal,
  DatePicker,
  Row,
  Col,
  Tooltip,
} from "antd";
import moment from "moment";

import HeaderBar from "../Components/Header/HeaderBar";
import ProfileSideBar from "../Components/ProfileSideBar";
import "./ProfileStyles.css";
import crossGreen from "../svgs/cross-green.svg";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import { Images } from "../Themes";
import ProfileTopBarTabs from "../Components/Shared/ProfileTopBar";
import ProgressBar from "../Components/Shared/Progressbar";

import ProcessingPerson from "../Components/Client/ProcessingPerson";
import Connections from "../Components/Client/Connections";
import PersonalInformation from "../Components/Client/PersonalInformation";
import CurrentVisa from "../Components/Client/CurrentVisa";
import Medicals from "../Components/Client/Medicals";
import Passport from "../Components/Client/Passport";
import InzLogin from "../Components/Client/InzLogin";
import NZQADetail from "../Components/Client/NZQADetail";
import BillingAddress from "../Components/Client/BillingAddress";

import PersonalInformationAddPartner from "../Components/Client/PersonalInfoAddPartner";
import PassportAddPartner from "../Components/Client/PassportAddPartner";
import MedicalsAddPartner from "../Components/Client/MedicalsAddPartner";
import InzLoginAddPartner from "../Components/Client/InzLoginAddPartner";
import NZQADetailAddPartner from "../Components/Client/NZQADetailAddPartner";
import BillingAddressAddPartner from "../Components/Client/BillingAddressAddPartner";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { saveAs } from "file-saver";

import headOption from "../Components/Header/HeaderTabOptions";
import activityData from "../Components/ClientActivity/ActivityData";
import AddAutoReminder from "../Components/Reminder/AddAutoReminder";
import { CheckOutlined } from "@material-ui/icons";
import queryString from "query-string";
import { apiRefresh } from "../services/api";
import AddCustomFields from "../Components/Client/AddCustomfields";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const topBar = [
  { tabName: "CLIENT INFORMATION", linkName: "/profile" },
  { tabName: "EMPLOYER INFORMATION", linkName: "/employer" },
  { tabName: "JOB HISTORY", linkName: "/job-history" },
  { tabName: "OTHER INFO", linkName: "/client-other-info" },
];

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
const dateFormat = "DD/MM/YYYY";

class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      selectedOption: null,
      date: new Date(),
      signed: false,
      onshore: false,
      active: false,
      allowUpdate: false,
      areaAccess: false,
      updLoading: false,
      addPartner: false,
      addClientImageUrl: "",
      loadUploadImage: false,
      fileList: [],
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      loadPartner: true,
      contentNotes: "",
      medicalNotes: "",
      headerOptions: [],
      signedModalVisible: false,
      users: [],
      showContList: true,
      branchProfileSetting: [],
    };

    this.props.onSetActiveKey(null);
    const initialQueryValue = queryString.parse(
      props.location && props.location.search
    );

    var clientIdParam =
      initialQueryValue && initialQueryValue.para
        ? initialQueryValue.para
        : null;
    var familyIdParam =
      initialQueryValue && initialQueryValue.fId ? initialQueryValue.fId : null;
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let clientProfileIdMainLoc = sessionStorage.getItem("clientProfileIdMain");
    let getFamilyId = sessionStorage.getItem("familyId");
    let getFamilyIdLoc = sessionStorage.getItem("familyId");
    let clientId = props.location.state
      ? props.location.state.id
      : clientIdParam || clientProfileIdMain;
    let familyId = props.location.state
      ? props.location.state.familyId
      : familyIdParam || getFamilyId;
    let data = {
      clientId: clientId,
      familyId: familyId,
    };

    let selectedTab = {
      headName: "Partner Detail",
      headPath: "/partner-profile",
    };

    setTimeout(() => {
      if (!clientId) {
        this.setState({ loadPartner: false });
      }
    }, 1500);

    this.props.onSetClientTab(selectedTab);
    this.getProfileSettings();
    if (clientId) {
      this.props
        .onGetPartner(data)
        .then((res) => {
          this.setProfileData(res.payload);

          this.props.onGetAutoImportEmailClient(res.payload.id).then((resp) => {
            if (resp.payload.length > 0 && resp.payload[0].isAutoImportEmail) {
              var emailQueueOpt = {
                url: `v1/ClientEmailImport/ImportEmailQueue`,
              };

              emailQueueOpt.types = [
                "EMAIL_QUEUE_SUCCESS",
                "EMAIL_QUEUE_FAILURE",
              ];
              apiRefresh.post(emailQueueOpt, { clientId: resp.payload[0].id });
            }
          });
          this.setState({ loadPartner: false });
        })
        .catch(() => {
          this.setState({ loadPartner: false });
        });
      this.props.onGetCountries();
      this.props.onGetAccessingAuth();
      this.props.onGetTeamMember();
      this.props.getClientSource();
      this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
      this.props.onGetBVisaCountries();
      this.props.onGetAgents().then((res) => {});
      this.props.onGetPermProcessingPerson(true).then((res) => {
        this.setState({ users: res.payload.users });
      });
    }
  }

  getProfileSettings = () => {
    const getBrnchProfileSetOpt = {
      url: `v1/branch/ShowHideClientProfileSetting`,
    };
    getBrnchProfileSetOpt.types = [
      "GET_CLIENT_PROFILE_SETTING_SUCCESS",
      "GET_CLIENT_PROFILE_SETTING_FAILURE",
    ];
    apiRefresh
      .get(getBrnchProfileSetOpt)
      .then((res) => {
        this.setState({ branchProfileSetting: res.clientProfileSetting });
      })
      .catch(() => {
        this.setState({ branchProfileSetting: [] });
      });
    const getClientProfileSetOpt = {
      url: `v1/users/ClientProfileSetting/00000000-0000-0000-0000-000000000000`,
    };
    getClientProfileSetOpt.types = [
      "GET_CLIENT_PROFILE_SETTING_SUCCESS",
      "GET_CLIENT_PROFILE_SETTING_FAILURE",
    ];
    apiRefresh
      .get(getClientProfileSetOpt)
      .then((res) => {
        this.setState({ showContList: res.clientProfileSetting });
      })
      .catch(() => {
        this.setState({ showContList: [] });
      });
  };

  setProfileData = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);
    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };
    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("agentId", data.agentUserId);
    this.props.onSetProfileData(JSON.stringify(profileData));
  };

  componentDidUpdate(PrevProps) {
    if (PrevProps.clientTab !== this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    }

    if (PrevProps.partnerProfileData !== this.props.partnerProfileData) {
      var email = "";
      var secondaryEmail = "";
      var otherEmail = "";
      if (this.props.partnerProfileData) {
        this.setState({
          addClientImageUrl: this.props.partnerProfileData.imageBlobUrl,
        });
      }

      if (
        this.props.partnerProfileData &&
        this.props.partnerProfileData.clientPermission
      ) {
        this.setState({
          signed: this.props.partnerProfileData.clientPermission.signed,
          onshore: this.props.partnerProfileData.clientPermission.onshore,
          active: this.props.partnerProfileData.clientPermission.active,
          allowUpdate: this.props.partnerProfileData.clientPermission
            .allowUpdate,
          areaAccess: this.props.partnerProfileData.clientPermission.areaAccess,
        });
      }

      if (this.props.partnerProfileData) {
        if (
          this.props.partnerProfileData &&
          this.props.partnerProfileData.emails
        ) {
          if (this.props.partnerProfileData.emails.length > 0) {
            for (
              var i = 0;
              i < this.props.partnerProfileData.emails.length;
              i++
            ) {
              if (this.props.partnerProfileData.emails[i].emailTypeId === 1) {
                email = this.props.partnerProfileData.emails[i].address;
              }
              if (this.props.partnerProfileData.emails[i].emailTypeId === 2) {
                secondaryEmail = this.props.partnerProfileData.emails[i]
                  .address;
              }
              if (this.props.partnerProfileData.emails[i].emailTypeId === 3) {
                otherEmail = this.props.partnerProfileData.emails[i].address;
              }
            }
          }
        }
        var billAddressData = null;
        var clientAddressData = null;
        if (
          this.props.partnerProfileData &&
          this.props.partnerProfileData.addresses
        ) {
          if (this.props.partnerProfileData.addresses.length > 0) {
            const findBillAddress = this.props.partnerProfileData.addresses.find(
              (obj) => obj.addressTypeId === 2
            );
            if (findBillAddress) {
              billAddressData = {
                contactPerson: findBillAddress.contactPerson,
                flat: findBillAddress.flat,
                streetNumber: findBillAddress.streetNumber,
                suburb: findBillAddress.suburb,
                city: findBillAddress.city,
                country: findBillAddress.country,
                zipcode: findBillAddress.zip,
              };
            }

            const findAddress = this.props.partnerProfileData.addresses.find(
              (obj) => obj.addressTypeId === 1
            );
            if (findAddress) {
              clientAddressData = {
                address: findAddress.city,
              };
            }
          }
        }

        var medicalData = null;
        if (this.props.partnerProfileData.medicals) {
          if (this.props.partnerProfileData.medicals.length > 0) {
            medicalData = {
              medicalIssueDate: this.props.partnerProfileData.medicals[0]
                .medicalIssueDate,
              medicalExpiryDate: this.props.partnerProfileData.medicals[0]
                .medicalExpiryDate,
              medicalGrading: this.props.partnerProfileData.medicals[0]
                .medicalGrading,
              xrayIssueDate: this.props.partnerProfileData.medicals[0]
                .xrayIssueDate,
              xrayExpiryDate: this.props.partnerProfileData.medicals[0]
                .xrayExpiryDate,
              xrayGrading: this.props.partnerProfileData.medicals[0]
                .xrayGrading,
              medicalNotes: this.props.partnerProfileData.medicals[0]
                .medicalNotes,
              medicalNotesDetail: this.props.partnerProfileData.medicals[0]
                .medicalNotesDetail,
            };
          }
        }
        var phoneData = {
          mobile: "",
          secondaryMobile: "",
          overseasMobile: "",
          landLine: "",
          otherMobile: "",
        };
        if (this.props.partnerProfileData.phones) {
          if (this.props.partnerProfileData.phones.length > 0) {
            const findMobile = this.props.partnerProfileData.phones.find(
              (obj) => obj.phoneTypeId === 1
            );
            if (findMobile) {
              phoneData.mobile = findMobile.contact;
            }
            const findSecondaryMobile = this.props.partnerProfileData.phones.find(
              (obj) => obj.phoneTypeId === 2
            );
            if (findSecondaryMobile) {
              phoneData.secondaryMobile = findSecondaryMobile.contact;
            }
            const findOverseasMobile = this.props.partnerProfileData.phones.find(
              (obj) => obj.phoneTypeId === 3
            );
            if (findOverseasMobile) {
              phoneData.overseasMobile = findOverseasMobile.contact;
            }
            const findLandLineMobile = this.props.partnerProfileData.phones.find(
              (obj) => obj.phoneTypeId === 4
            );
            if (findLandLineMobile) {
              phoneData.landLine = findLandLineMobile.contact;
            }
            const findOtherMobile = this.props.partnerProfileData.phones.find(
              (obj) => obj.phoneTypeId === 5
            );
            if (findOtherMobile) {
              phoneData.otherMobile = findOtherMobile.contact;
            }
          }
        }
      }
      // changes
      // this.formRef.current.setFieldsValue({
      //   visaCountryId: this.props.partnerProfileData.visaCountryId.toString(),
      //   visaCountryType: this.props.partnerProfileData.visaCountyType.toString(),
      //   title: this.props.partnerProfileData.title,
      //   middleName: this.props.partnerProfileData.middleName,
      //   gender: this.props.partnerProfileData.gender,
      //   dateOfBirth: moment(this.props.partnerProfileData.dateOfBirth),
      //   address: clientAddressData ? clientAddressData.address : "",
      //   nationalityId: this.props.partnerProfileData.nationalityId,
      //   saleDate: moment(this.props.partnerProfileData.saleDate),
      //   sourceId: this.props.partnerProfileData.sourceId.toString(),
      //   jobSectorId: this.props.partnerProfileData.jobSectorId.toString(),
      //   companyOptional: this.props.partnerProfileData.companyOptional,
      //   clientSerial: this.props.partnerProfileData.clientSerial,
      //   nationalityCountry: this.props.partnerProfileData.nationalityCountry,
      //   firstName: this.props.partnerProfileData.firstName,
      //   lastName: this.props.partnerProfileData.lastName,
      //   age: this.props.partnerProfileData.age,
      //   dealWorth: this.props.partnerProfileData.dealWorth,
      //   maritalStatus: this.props.partnerProfileData.maritalStatus,
      //   dependentChildren: this.props.partnerProfileData.dependentChildren,
      //   sourceDescription: this.props.partnerProfileData.sourceDescription,
      //   occupation: this.props.partnerProfileData.occupation,
      //   visaDenied: this.props.partnerProfileData.visaDenied,
      //   deniedText: this.props.partnerProfileData.deniedText,
      //   visaText: this.props.partnerProfileData.visaText,
      //   currentVisaTypeId: this.props.partnerProfileData.currentVisaTypeId.toString(),
      //   currentNewZealandVisaExpiry: moment(
      //     this.props.partnerProfileData.currentNewZealandVisaExpiry
      //   ),
      //   travelConditionsValidTo: moment(
      //     this.props.partnerProfileData.travelConditionsValidTo
      //   ),
      //   clientNumber: this.props.partnerProfileData.clientNumber,
      //   inzUserName: this.props.partnerProfileData.inzUserName,
      //   inzPassword: this.props.partnerProfileData.inzPassword,
      //   inzFeeDate: moment(this.props.partnerProfileData.inzFeeDate),
      //   email: email,
      //   secondaryEmail: secondaryEmail,
      //   otherEmail: otherEmail,
      //   contactPerson: billAddressData ? billAddressData.contactPerson : "",
      //   flat: billAddressData ? billAddressData.flat : "",
      //   streetNumber: billAddressData ? billAddressData.streetNumber : "",
      //   suburb: billAddressData ? billAddressData.suburb : "",
      //   city: billAddressData ? billAddressData.city : "",
      //   billCountry: billAddressData ? billAddressData.country : "",
      //   zipcode: billAddressData ? billAddressData.zipcode : "",
      //   clientAddress: clientAddressData ? clientAddressData.address : "",
      //   mobilePhone: phoneData ? phoneData.mobile : "",
      //   secondaryMobile: phoneData ? phoneData.secondaryMobile : "",
      //   overseasMobile: phoneData ? phoneData.overseasMobile : "",
      //   landLine: phoneData ? phoneData.landLine : "",
      //   otherMobile: phoneData ? phoneData.otherMobile : "",
      //   medicalIssueDate: medicalData
      //     ? moment(medicalData.medicalIssueDate)
      //     : "",
      //   medicalExpiryDate: medicalData
      //     ? moment(medicalData.medicalExpiryDate)
      //     : "",
      //   medicalGrading: medicalData ? medicalData.medicalGrading : "",
      //   xrayIssueDate: medicalData ? moment(medicalData.xrayIssueDate) : "",
      //   xrayExpiryDate: medicalData ? moment(medicalData.xrayExpiryDate) : "",
      //   xrayGrading: medicalData ? medicalData.xrayGrading : "",
      //   medicalNotes: medicalData ? medicalData.medicalNotes : "",
      //   medicalNotesDetail: medicalData ? medicalData.medicalNotesDetail : "",
      //   passportNo:
      //     this.props.partnerProfileData.passports && this.props.partnerProfileData.passports.length > 0
      //       ? this.props.partnerProfileData.passports[0].passportNo
      //       : "",
      //   passportCountry:
      //     this.props.partnerProfileData.passports && this.props.partnerProfileData.passports.length > 0
      //       ? this.props.partnerProfileData.passports[0].passportCountry.toString()
      //       : "",
      //   passportIssueDate:
      //     this.props.partnerProfileData.passports && this.props.partnerProfileData.passports.length > 0
      //       ? moment(
      //           this.props.partnerProfileData.passports[0].passportIssueDate
      //         )
      //       : "",
      //   passportExpiryDate:
      //     this.props.partnerProfileData.passports && this.props.partnerProfileData.passports.length > 0
      //       ? moment(
      //           this.props.partnerProfileData.passports[0].passportExpiryDate
      //         )
      //       : "",
      //   secondPassportNo:
      //     this.props.partnerProfileData.passports && this.props.partnerProfileData.passports.length > 1
      //       ? this.props.partnerProfileData.passports[1].passportNo
      //       : "",
      //   secondPassportCountry:
      //     this.props.partnerProfileData.passports && this.props.partnerProfileData.passports.length > 1
      //       ? this.props.partnerProfileData.passports[1].passportCountry.toString()
      //       : "",
      //   secondPassportIssueDate:
      //     this.props.partnerProfileData.passports && this.props.partnerProfileData.passports.length > 1
      //       ? moment(
      //           this.props.partnerProfileData.passports[1].passportIssueDate
      //         )
      //       : "",
      //   secondPassportExpiryDate:
      //     this.props.partnerProfileData.passports && this.props.partnerProfileData.passports.length > 1
      //       ? moment(
      //           this.props.partnerProfileData.passports[1].passportExpiryDate
      //         )
      //       : ""
      // });
    }
  }

  formRef = React.createRef();

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeDate = (date) => this.setState({ date });

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  addPoliceCertificateInfo = (policeCertificateData) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let data = {
      clientId: clientprofileid,
      issueDate: "2020-12-07T08:08:09.142Z",
      certificateExpiryDate: "2020-12-07T08:08:09.142Z",
      country: 168,
    };
  };

  addCertificate = (data) => {
    this.setState({ loadPartner: true });
    this.props
      .onAddPoliceCertificate(data)
      .then(() => {
        this.setState({ loadPartner: false });
        message.success("Police Certificate added successfully");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;

        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetPartner(data);
      })
      .catch((err) => {
        this.setState({ loadPartner: false });
      });
  };

  onGetProfile = () => {
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;

    let data = {
      clientId: clientId,
      familyId: familyId,
    };
    this.props.onGetPartner(data);
  };

  addAuthority = (data) => {
    this.setState({ loadPartner: true });
    this.props
      .onAddAccAuthorities(data)
      .then(() => {
        this.setState({ loadPartner: false });
        message.success("Accessing Authority added successfully");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");

        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetPartner(data);
      })
      .catch((err) => {
        this.setState({ loadPartner: false });
      });
  };

  removeCertificate = (data) => {
    //
    this.props.onRemovePoliceCertificate(data).then(() => {
      message.success("Police Certificate removed successfully");
      let clientprofileid = sessionStorage.getItem("clientProfileIdMain");

      let familyId = sessionStorage.getItem("familyId");
      let clientId = clientprofileid;
      let data = {
        clientId: clientId,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    });
  };

  removeAuthority = (data) => {
    //
    this.props.onRemoveAccesingAuthority(data).then(() => {
      message.success("Accessing Authority removed successfully");
      let clientprofileid = sessionStorage.getItem("clientprofileid");

      let familyId = sessionStorage.getItem("familyId");
      let clientId = clientprofileid;
      let data = {
        clientId: clientId,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    });
  };

  personalInfoUpdate = (values, content) => {
    let date1 = new Date();
    let date2 = new Date(this.props.partnerProfileData.dateOfBirth);
    let yearsDiff = 0;
    if (
      this.props.partnerProfileData.dateOfBirth !== "1900-01-01T00:00:00+00:00"
    ) {
      yearsDiff = date1.getFullYear() - date2.getFullYear();
    }
    this.setState({ loadPartner: true });
    const userId = localStorage.getItem("userId");
    let addressData = [];
    let phonesData = [];
    let emailData = [];
    if (this.props.partnerProfileData.addresses.length > 0) {
      for (var i = 0; i < this.props.partnerProfileData.addresses.length; i++) {
        let addressValues = {
          id: this.props.partnerProfileData.addresses[i].id,
          clientId: this.props.partnerProfileData.addresses[i].clientId,
          contactPerson: this.props.partnerProfileData.addresses[i]
            .contactPerson,
          flat: this.props.partnerProfileData.addresses[i].flat,
          building: this.props.partnerProfileData.addresses[i].building,
          streetName: this.props.partnerProfileData.addresses[i].streetName,
          suburb: this.props.partnerProfileData.addresses[i].suburb,
          streetNumber: this.props.partnerProfileData.addresses[i].streetNumber,
          city:
            this.props.partnerProfileData.addresses[i].addressTypeId === 1
              ? values.address || ""
              : this.props.partnerProfileData.addresses[i].city,
          state: this.props.partnerProfileData.addresses[i].state,
          zip: this.props.partnerProfileData.addresses[i].zip,
          country: this.props.partnerProfileData.addresses[i].country,
          addressTypeId: this.props.partnerProfileData.addresses[i]
            .addressTypeId,
        };
        addressData.push(addressValues);
      }
      // this.props.onUpdClientAddress(addressData);
    } else {
      let addresses = [
        {
          id: 0,
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: values.address || "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 1,
        },
        {
          id: 0,
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 5,
        },
      ];
      addressData = [...addresses];
    }
    if (
      this.props.partnerProfileData.emails &&
      this.props.partnerProfileData.emails.length > 0
    ) {
      for (var i = 0; i < this.props.partnerProfileData.emails.length; i++) {
        let emailValues = {
          id: this.props.partnerProfileData.emails[i].id,
          clientId: this.props.partnerProfileData.emails[i].clientId,
          address:
            this.props.partnerProfileData.emails[i].emailTypeId === 1
              ? values.email || ""
              : this.props.partnerProfileData.emails[i].emailTypeId === 2
              ? values.secondaryEmail || ""
              : values.otherEmail || "",
          emailTypeId: this.props.partnerProfileData.emails[i].emailTypeId,
        };
        emailData.push(emailValues);
      }
      // this.props.onUpdClientEmail(emailData);
    } else {
      let primaryEmail = {
        id: 0,
        address: values.email || "",
        emailTypeId: 1,
        clientId: sessionStorage.getItem("clientprofileid"),
      };
      let secondaryEmail = {
        id: 0,
        address: values.secondaryEmail || "",
        emailTypeId: 2,
        clientId: sessionStorage.getItem("clientprofileid"),
      };
      let otherEmail = {
        id: 0,
        address: values.otherEmail || "",
        emailTypeId: 3,
        clientId: sessionStorage.getItem("clientprofileid"),
      };
      emailData.push(primaryEmail);
      emailData.push(secondaryEmail);
      emailData.push(otherEmail);
    }
    if (this.props.partnerProfileData.phones.length > 0) {
      for (var i = 0; i < this.props.partnerProfileData.phones.length; i++) {
        let phonesValues = {
          id: this.props.partnerProfileData.phones[i].id,
          clientId: this.props.partnerProfileData.phones[i].clientId,
          contact:
            this.props.partnerProfileData.phones[i].phoneTypeId === 1
              ? (values.mobilePhone && values.mobilePhone.toString()) || ""
              : this.props.partnerProfileData.phones[i].phoneTypeId === 2
              ? (values.secondaryMobile && values.secondaryMobile.toString()) ||
                ""
              : this.props.partnerProfileData.phones[i].phoneTypeId === 3
              ? (values.overseasMobile && values.overseasMobile.toString()) ||
                ""
              : this.props.partnerProfileData.phones[i].phoneTypeId === 4
              ? (values.landLine && values.landLine.toString()) || ""
              : (values.otherMobile && values.otherMobile.toString()) || "",
          countryCodeId:
            this.props.partnerProfileData.phones[i].phoneTypeId === 1
              ? parseInt(values.countryCodeId) ||
                this.props.partnerProfileData.phones[i].countryCodeId
              : this.props.partnerProfileData.phones[i].countryCodeId,
          phoneTypeId: this.props.partnerProfileData.phones[i].phoneTypeId,
        };
        phonesData.push(phonesValues);
      }
    } else {
      let _phones = [
        {
          id: 0,
          countryCodeId: values.countryCodeId
            ? parseInt(values.countryCodeId)
            : 0,
          phoneTypeId: 1,
          contact: (values.mobilePhone && values.mobilePhone.toString()) || "",
        },
        {
          id: 0,
          countryCodeId: 168,
          phoneTypeId: 2,
          contact:
            (values.secondaryMobile && values.secondaryMobile.toString()) || "",
        },
        {
          id: 0,
          countryCodeId: 168,
          phoneTypeId: 3,
          contact:
            (values.overseasMobile && values.overseasMobile.toString()) || "",
        },
        {
          id: 0,
          countryCodeId: 168,
          phoneTypeId: 4,
          contact: (values.landLine && values.landLine.toString()) || "",
        },
        {
          id: 0,
          countryCodeId: 168,
          phoneTypeId: 5,
          contact: (values.otherMobile && values.otherMobile.toString()) || "",
        },
      ];
      phonesData = [..._phones];

      // this.props.onUpdClientPhone(phonesData);
    }
    var dateOfBirth = "";
    if (values.dateOfBirth) {
      dateOfBirth = moment(values.dateOfBirth).format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
    }

    let data = {
      id: this.props.partnerProfileData.id,
      clientNumber: this.props.partnerProfileData.clientNumber,
      familyId: this.props.partnerProfileData.familyId,
      processingGroupId: 0,
      agentId: values.agentId || "00000000-0000-0000-0000-000000000000",
      clientTag: this.props.partnerProfileData.clientTag,
      firstName: values.firstName
        ? values.firstName
        : this.props.partnerProfileData.firstName,
      lastName: values.lastName ? values.lastName : "",
      middleName: values.middleName
        ? values.middleName
        : this.props.partnerProfileData.middleName,
      title: values.title ? values.title : "",
      gender: values.gender ? parseInt(values.gender) : 0,
      dateOfBirth: dateOfBirth ? dateOfBirth : "1900-01-01T00:00:00+00:00",
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : this.props.partnerProfileData.maritalStatus,
      dependentChildren: values.dependentChildren
        ? parseInt(values.dependentChildren)
        : 0,
      notes: content || "",
      occupation: values.occupation ? values.occupation : "",
      occupationOrganization: this.props.partnerProfileData
        .occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : this.props.partnerProfileData.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : this.props.partnerProfileData.inzPassword,
      imageBlobUrl: this.props.partnerProfileData.imageBlobUrl,
      nationalityId: values.nationalityId !== "" ? values.nationalityId : "",
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : "",
      skypeID: this.props.partnerProfileData.skypeID,
      preferredName: this.props.partnerProfileData.preferredName,
      isSubscribed: this.props.partnerProfileData.isSubscribed,
      arbitaryJson: this.props.partnerProfileData.arbitaryJson,
      dependentClientIds: this.props.partnerProfileData.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : this.props.partnerProfileData.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? values.currentNewZealandVisaExpiry
        : this.props.partnerProfileData.currentNewZealandVisaExpiry,
      travelConditionsValidTo: values.travelConditionsValidTo
        ? values.travelConditionsValidTo
        : this.props.partnerProfileData.travelConditionsValidTo,
      visaText: values.visaText
        ? values.visaText
        : this.props.partnerProfileData.visaText,
      visaDenied: values.visaDenied,
      deniedText: values.deniedText ? values.deniedText : "",
      clientNumberIZM: this.props.partnerProfileData.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : this.props.partnerProfileData.inzFeeDate,
      interestedVisa: parseInt(values.interestedVisa) || 0,
      memberType: this.props.partnerProfileData.memberType,
      clientId: this.props.partnerProfileData.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? values.nzqaOnlineSubDate
        : this.props.partnerProfileData.nzqaOnlineSubDate,
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? values.nzqaDocumentSubDate
        : this.props.partnerProfileData.nzqaDocumentSubDate,
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? values.nzqaDocumentRetDate
        : this.props.partnerProfileData.nzqaDocumentRetDate,
      visaCountryId: parseInt(
        values.visaCountryId
          ? values.visaCountryId
          : this.props.partnerProfileData.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : this.props.partnerProfileData.visaCountyType
      ),
      age: yearsDiff !== 0 ? yearsDiff : this.props.partnerProfileData.age,
      jobSectorId: parseInt(
        values.jobSectorId
          ? values.jobSectorId
          : this.props.partnerProfileData.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId
          ? values.sourceId
          : this.props.partnerProfileData.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : "",
      clientSerial: values.clientSerial
        ? values.clientSerial
        : this.props.partnerProfileData.clientSerial,
      companyOptional: values.companyOptional ? values.companyOptional : "",
      dealWorth: values.dealWorth ? values.dealWorth : "",
      agentUserId: values.agentUserId || "00000000-0000-0000-0000-000000000000",
      saleDate: values.saleDate
        ? moment(values.saleDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      customFields: values.customFields || [],
      clientPermission: {
        signed: this.state.signed,
        onshore: this.state.onshore,
        active: this.state.active,
        allowUpdate: this.state.allowUpdate,
        areaAccess: this.state.areaAccess,
      },
      addresses: addressData,
      clientEmails: emailData,
      clientPhones: phonesData,
      clientMedical: this.props.partnerProfileData.medicals[0],
      passports: this.props.partnerProfileData.passports,
      policeCertificates: this.props.partnerProfileData.certificates,
    };

    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success("Partner profile updated successfully");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let userName = localStorage.getItem("userName");
        let myData = {
          clientName: "",
          logMessage: "Partner Information updated by " + userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(myData);
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetPartner(data).then((res) => {
          this.setProfileData(res.payload);
        });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Partner profile update failed");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");

        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetPartner(data);
      });
  };

  onUpdateCurrentVisa = (values) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    let data = {
      id: this.props.partnerProfileData.id,
      clientNumber: this.props.partnerProfileData.clientNumber,
      familyId: this.props.partnerProfileData.familyId,
      processingGroupId: 0,
      agentId: this.props.partnerProfileData.agentId,
      clientTag: this.props.partnerProfileData.clientTag,
      firstName: values.firstName
        ? values.firstName
        : this.props.partnerProfileData.firstName,
      lastName: values.lastName
        ? values.lastName
        : this.props.partnerProfileData.lastName,
      middleName: values.middleName
        ? values.middleName
        : this.props.partnerProfileData.middleName,
      title: values.title ? values.title : this.props.partnerProfileData.title,
      gender: values.gender
        ? values.gender
        : this.props.partnerProfileData.gender,
      dateOfBirth: this.props.partnerProfileData.dateOfBirth,
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : this.props.partnerProfileData.maritalStatus,
      dependentChildren: values.dependentChildren
        ? values.dependentChildren
        : this.props.partnerProfileData.dependentChildren,
      notes: "string",
      occupation: values.occupation
        ? values.occupation
        : this.props.partnerProfileData.occupation,
      occupationOrganization: this.props.partnerProfileData
        .occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : this.props.partnerProfileData.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : this.props.partnerProfileData.inzPassword,
      imageBlobUrl: this.props.partnerProfileData.imageBlobUrl,
      nationalityId: values.nationalityId
        ? values.nationalityId
        : this.props.partnerProfileData.nationalityId,
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : this.props.partnerProfileData.nationalityCountry,
      skypeID: this.props.partnerProfileData.skypeID,
      preferredName: this.props.partnerProfileData.preferredName,
      isSubscribed: this.props.partnerProfileData.isSubscribed,
      arbitaryJson: this.props.partnerProfileData.arbitaryJson,
      dependentClientIds: this.props.partnerProfileData.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId ? values.currentVisaTypeId : 0
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? moment(values.currentNewZealandVisaExpiry).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      travelConditionsValidTo: values.travelConditionsValidTo
        ? moment(values.travelConditionsValidTo).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      visaText: values.visaText ? values.visaText : "",
      visaDenied: this.props.partnerProfileData.visaDenied,
      deniedText: values.deniedText
        ? values.deniedText
        : this.props.partnerProfileData.deniedText,
      clientNumberIZM: this.props.partnerProfileData.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : this.props.partnerProfileData.inzFeeDate,
      interestedVisa: this.props.partnerProfileData.interestedVisa,
      memberType: this.props.partnerProfileData.memberType,
      clientId: this.props.partnerProfileData.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? values.nzqaOnlineSubDate
        : this.props.partnerProfileData.nzqaOnlineSubDate,
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? values.nzqaDocumentSubDate
        : this.props.partnerProfileData.nzqaDocumentSubDate,
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? values.nzqaDocumentRetDate
        : this.props.partnerProfileData.nzqaDocumentRetDate,
      visaCountryId: parseInt(
        values.visaCountryId
          ? values.visaCountryId
          : this.props.partnerProfileData.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : this.props.partnerProfileData.visaCountyType
      ),
      age: values.age ? values.age : this.props.partnerProfileData.age,
      jobSectorId: parseInt(
        values.jobSectorId
          ? values.jobSectorId
          : this.props.partnerProfileData.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId
          ? values.sourceId
          : this.props.partnerProfileData.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : this.props.partnerProfileData.sourceDescription,
      clientSerial: values.clientSerial
        ? values.clientSerial
        : this.props.partnerProfileData.clientSerial,
      companyOptional: values.companyOptional
        ? values.companyOptional
        : this.props.partnerProfileData.companyOptional,
      dealWorth: values.dealWorth
        ? values.dealWorth
        : this.props.partnerProfileData.dealWorth,
      saleDate: values.saleDate
        ? values.saleDate
        : this.props.partnerProfileData.saleDate,
      agentUserId:
        this.props.partnerProfileData.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      customFields: values.customFields || [],
      clientPermission: this.props.partnerProfileData.clientPermission,
      addresses: this.props.partnerProfileData.addresses,
      clientEmails: this.props.partnerProfileData.emails,
      clientPhones: this.props.partnerProfileData.phones,
      clientMedical: this.props.partnerProfileData.medicals[0],
      passports: this.props.partnerProfileData.passports,
      policeCertificates: this.props.partnerProfileData.certificates,
    };
    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success("Partner current visa updated successfully");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Partner " +
            this.props.partnerProfileData.firstName +
            " " +
            this.props.partnerProfileData.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let clientIds = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetPartner(clientIds);
        if (
          moment(data.currentNewZealandVisaExpiry).format("DD/MM/YYYY") !=
            moment(
              this.props.partnerProfileData.currentNewZealandVisaExpiry
            ).format("DD/MM/YYYY") &&
          moment(data.currentNewZealandVisaExpiry).format("DD/MM/YYYY") !=
            "01/01/1900"
        ) {
          let myData = {
            title: "Current Visa Expiry",
            reminderDate: values.currentNewZealandVisaExpiry
              ? moment(values.currentNewZealandVisaExpiry).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 10,
          };

          AddAutoReminder(myData);
        }
        if (
          moment(data.travelConditionsValidTo).format("DD/MM/YYYY") !=
            moment(
              this.props.partnerProfileData.travelConditionsValidTo
            ).format("DD/MM/YYYY") &&
          moment(data.travelConditionsValidTo).format("DD/MM/YYYY") !=
            "01/01/1900"
        ) {
          let tcData = {
            title: "Travel Condition Valid To",
            reminderDate: values.travelConditionsValidTo
              ? moment(values.travelConditionsValidTo).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 13,
          };
          AddAutoReminder(tcData);
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Partner current visa update failed");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");

        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetPartner(data);
      });
  };

  onUpdateNZQA = (values) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    let data = {
      id: this.props.partnerProfileData.id,
      clientNumber: this.props.partnerProfileData.clientNumber,
      familyId: this.props.partnerProfileData.familyId,
      processingGroupId: 0,
      agentId: this.props.partnerProfileData.agentId,
      clientTag: this.props.partnerProfileData.clientTag,
      firstName: values.firstName
        ? values.firstName
        : this.props.partnerProfileData.firstName,
      lastName: values.lastName
        ? values.lastName
        : this.props.partnerProfileData.lastName,
      middleName: values.middleName
        ? values.middleName
        : this.props.partnerProfileData.middleName,
      title: values.title ? values.title : this.props.partnerProfileData.title,
      gender: values.gender
        ? values.gender
        : this.props.partnerProfileData.gender,
      dateOfBirth: this.props.partnerProfileData.dateOfBirth,
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : this.props.partnerProfileData.maritalStatus,
      dependentChildren: values.dependentChildren
        ? values.dependentChildren
        : this.props.partnerProfileData.dependentChildren,
      notes: "string",
      occupation: values.occupation
        ? values.occupation
        : this.props.partnerProfileData.occupation,
      occupationOrganization: this.props.partnerProfileData
        .occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : this.props.partnerProfileData.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : this.props.partnerProfileData.inzPassword,
      imageBlobUrl: this.props.partnerProfileData.imageBlobUrl,
      nationalityId: values.nationalityId
        ? values.nationalityId
        : this.props.partnerProfileData.nationalityId,
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : this.props.partnerProfileData.nationalityCountry,
      skypeID: this.props.partnerProfileData.skypeID,
      preferredName: this.props.partnerProfileData.preferredName,
      isSubscribed: this.props.partnerProfileData.isSubscribed,
      arbitaryJson: this.props.partnerProfileData.arbitaryJson,
      dependentClientIds: this.props.partnerProfileData.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : this.props.partnerProfileData.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? values.currentNewZealandVisaExpiry
        : this.props.partnerProfileData.currentNewZealandVisaExpiry,
      travelConditionsValidTo: values.travelConditionsValidTo
        ? values.travelConditionsValidTo
        : this.props.partnerProfileData.travelConditionsValidTo,
      visaText: values.visaText
        ? values.visaText
        : this.props.partnerProfileData.visaText,
      visaDenied: this.props.partnerProfileData.visaDenied,
      deniedText: values.deniedText
        ? values.deniedText
        : this.props.partnerProfileData.deniedText,
      clientNumberIZM: this.props.partnerProfileData.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : this.props.partnerProfileData.inzFeeDate,
      interestedVisa: this.props.partnerProfileData.interestedVisa,
      memberType: this.props.partnerProfileData.memberType,
      clientId: this.props.partnerProfileData.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? moment(values.nzqaOnlineSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? moment(values.nzqaDocumentSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? moment(values.nzqaDocumentRetDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      visaCountryId: parseInt(
        values.visaCountryId
          ? values.visaCountryId
          : this.props.partnerProfileData.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : this.props.partnerProfileData.visaCountyType
      ),
      age: values.age ? values.age : this.props.partnerProfileData.age,
      jobSectorId: parseInt(
        values.jobSectorId
          ? values.jobSectorId
          : this.props.partnerProfileData.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId
          ? values.sourceId
          : this.props.partnerProfileData.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : this.props.partnerProfileData.sourceDescription,
      clientSerial: values.clientSerial
        ? values.clientSerial
        : this.props.partnerProfileData.clientSerial,
      companyOptional: values.companyOptional
        ? values.companyOptional
        : this.props.partnerProfileData.companyOptional,
      dealWorth: values.dealWorth
        ? values.dealWorth
        : this.props.partnerProfileData.dealWorth,
      saleDate: values.saleDate
        ? values.saleDate
        : this.props.partnerProfileData.saleDate,
      agentUserId:
        this.props.partnerProfileData.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      customFields: values.customFields || [],
      clientPermission: this.props.partnerProfileData.clientPermission,
      addresses: this.props.partnerProfileData.addresses,
      clientEmails: this.props.partnerProfileData.emails,
      clientPhones: this.props.partnerProfileData.phones,
      clientMedical: this.props.partnerProfileData.medicals[0],
      passports: this.props.partnerProfileData.passports,
      policeCertificates: this.props.partnerProfileData.certificates,
    };
    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success("Partner NZQA detail updated successfully");
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Partner " +
            this.props.partnerProfileData.firstName +
            " " +
            this.props.partnerProfileData.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetPartner(data);
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Partner NZQA detail update failed");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetPartner(data);
      });
  };

  onUpdateMedicals = (values, content) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });

    let data = null;
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;
    let clientData = {
      clientId: clientId,
      familyId: familyId,
    };
    if (
      this.props.partnerProfileData &&
      this.props.partnerProfileData.medicals.length > 0
    ) {
      data = [
        {
          id: this.props.partnerProfileData.medicals[0].id,
          clientId: this.props.partnerProfileData.id,
          er: values.er || "",
          medicalIssueDate: values.medicalIssueDate
            ? moment(values.medicalIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          medicalExpiryDate: values.medicalExpiryDate
            ? moment(values.medicalExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          xrayIssueDate: values.xrayIssueDate
            ? moment(values.xrayIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          xrayExpiryDate: values.xrayExpiryDate
            ? moment(values.xrayExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          medicalGrading: values.medicalGrading.toString() || "",
          xrayGrading: values.xrayGrading.toString() || "",
          medicalNotes: values.medicalNotes || "",
          medicalNotesDetail: content,
        },
      ];
    } else {
      data = [
        {
          clientId: this.props.partnerProfileData.id,
          er: values.er || "",
          medicalIssueDate: values.medicalIssueDate
            ? moment(values.medicalIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          medicalExpiryDate: values.medicalExpiryDate
            ? moment(values.medicalExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          xrayIssueDate: values.xrayIssueDate
            ? moment(values.xrayIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          xrayExpiryDate: values.xrayExpiryDate
            ? moment(values.xrayExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          medicalGrading: values.medicalGrading.toString() || "",
          xrayGrading: values.xrayGrading.toString() || "",
          medicalNotes: values.medicalNotes || "",
          medicalNotesDetail: content,
        },
      ];
    }
    let cpData = this.props.partnerProfileData;
    cpData.customFields = values.customFields || [];
    cpData.clientEmails = cpData.emails;
    cpData.clientPhones = cpData.phones;
    cpData.clientMedical = data[0];
    cpData.policeCertificates = cpData.certificates;
    this.props
      .onUpdClientMedical(data)
      .then(() => {
        message.success("Partner medical detail updated successfully");

        this.props
          .onUpdatePartner(cpData)
          .then(() => {
            this.setState({ updLoading: false, loadPartner: false });
            this.props.onGetPartner(clientData);
          })
          .catch(() => {
            this.setState({ updLoading: false, loadPartner: false });
          });
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Partner " +
            this.props.partnerProfileData.firstName +
            " " +
            this.props.partnerProfileData.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        if (
          moment(data[0].medicalExpiryDate).format("DD/MM/YYYY") !=
            moment(
              this.props.partnerProfileData.medicals[0].medicalExpiryDate
            ).format("DD/MM/YYYY") &&
          moment(data[0].medicalExpiryDate).format("DD/MM/YYYY") != "01/01/1900"
        ) {
          let myData = {
            title: "Medical Expiry",
            reminderDate: values.medicalExpiryDate
              ? moment(values.medicalExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 5,
          };
          AddAutoReminder(myData);
        }
        if (
          moment(data[0].xrayExpiryDate).format("DD/MM/YYYY") !=
            moment(
              this.props.partnerProfileData.medicals[0].xrayExpiryDate
            ).format("DD/MM/YYYY") &&
          moment(data[0].xrayExpiryDate).format("DD/MM/YYYY") != "01/01/1900"
        ) {
          let xrayData = {
            title: "Xray Expiry",
            reminderDate: values.xrayExpiryDate
              ? moment(values.xrayExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 4,
          };
          AddAutoReminder(xrayData);
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Partner medical detail update failed");
        this.props.onGetPartner(clientData);
      });
  };

  onUpdateInzUserDetail = (values) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    let data = {
      id: this.props.partnerProfileData.id,
      clientNumber: values.clientNumber || "",
      familyId: this.props.partnerProfileData.familyId,
      processingGroupId: 0,
      agentId: this.props.partnerProfileData.agentId,
      clientTag: this.props.partnerProfileData.clientTag,
      firstName: values.firstName
        ? values.firstName
        : this.props.partnerProfileData.firstName,
      lastName: values.lastName
        ? values.lastName
        : this.props.partnerProfileData.lastName,
      middleName: values.middleName
        ? values.middleName
        : this.props.partnerProfileData.middleName,
      title: values.title ? values.title : this.props.partnerProfileData.title,
      gender: values.gender
        ? values.gender
        : this.props.partnerProfileData.gender,
      dateOfBirth: this.props.partnerProfileData.dateOfBirth,
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : this.props.partnerProfileData.maritalStatus,
      dependentChildren: values.dependentChildren
        ? values.dependentChildren
        : this.props.partnerProfileData.dependentChildren,
      notes: "string",
      occupation: values.occupation
        ? values.occupation
        : this.props.partnerProfileData.occupation,
      occupationOrganization: this.props.partnerProfileData
        .occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : this.props.partnerProfileData.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : this.props.partnerProfileData.inzPassword,
      imageBlobUrl: this.props.partnerProfileData.imageBlobUrl,
      nationalityId: values.nationalityId
        ? values.nationalityId
        : this.props.partnerProfileData.nationalityId,
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : this.props.partnerProfileData.nationalityCountry,
      skypeID: this.props.partnerProfileData.skypeID,
      preferredName: this.props.partnerProfileData.preferredName,
      isSubscribed: this.props.partnerProfileData.isSubscribed,
      arbitaryJson: this.props.partnerProfileData.arbitaryJson,
      dependentClientIds: this.props.partnerProfileData.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : this.props.partnerProfileData.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? values.currentNewZealandVisaExpiry
        : this.props.partnerProfileData.currentNewZealandVisaExpiry,
      travelConditionsValidTo: values.travelConditionsValidTo
        ? values.travelConditionsValidTo
        : this.props.partnerProfileData.travelConditionsValidTo,
      visaText: values.visaText
        ? values.visaText
        : this.props.partnerProfileData.visaText,
      visaDenied: this.props.partnerProfileData.visaDenied,
      deniedText: values.deniedText
        ? values.deniedText
        : this.props.partnerProfileData.deniedText,
      clientNumberIZM: this.props.partnerProfileData.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : this.props.partnerProfileData.inzFeeDate,
      interestedVisa: this.props.partnerProfileData.interestedVisa,
      memberType: this.props.partnerProfileData.memberType,
      clientId: this.props.partnerProfileData.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? values.nzqaOnlineSubDate
        : this.props.partnerProfileData.nzqaOnlineSubDate,
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? values.nzqaDocumentSubDate
        : this.props.partnerProfileData.nzqaDocumentSubDate,
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? values.nzqaDocumentRetDate
        : this.props.partnerProfileData.nzqaDocumentRetDate,
      visaCountryId: parseInt(
        values.visaCountryId
          ? values.visaCountryId
          : this.props.partnerProfileData.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : this.props.partnerProfileData.visaCountyType
      ),
      age: values.age ? values.age : this.props.partnerProfileData.age,
      jobSectorId: parseInt(
        values.jobSectorId
          ? values.jobSectorId
          : this.props.partnerProfileData.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId
          ? values.sourceId
          : this.props.partnerProfileData.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : this.props.partnerProfileData.sourceDescription,
      clientSerial: values.clientSerial
        ? values.clientSerial
        : this.props.partnerProfileData.clientSerial,
      companyOptional: values.companyOptional
        ? values.companyOptional
        : this.props.partnerProfileData.companyOptional,
      dealWorth: values.dealWorth
        ? values.dealWorth
        : this.props.partnerProfileData.dealWorth,
      saleDate: values.saleDate
        ? values.saleDate
        : this.props.partnerProfileData.saleDate,
      agentUserId:
        this.props.partnerProfileData.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      customFields: values.customFields || [],
      clientPermission: this.props.partnerProfileData.clientPermission,
      addresses: this.props.partnerProfileData.addresses,
      clientEmails: this.props.partnerProfileData.emails,
      clientPhones: this.props.partnerProfileData.phones,
      clientMedical: this.props.partnerProfileData.medicals[0],
      passports: this.props.partnerProfileData.passports,
      policeCertificates: this.props.partnerProfileData.certificates,
    };
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;
    let clientData = {
      clientId: clientId,
      familyId: familyId,
    };
    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success("Profile updated successfully");
        this.props.onGetPartner(clientData);
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Partner " +
            this.props.partnerProfileData.firstName +
            " " +
            this.props.partnerProfileData.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Profile update failed");
        this.props.onGetPartner(clientData);
      });
  };

  onUpdatePassport = (values) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    var setReminder = false;
    var setSecondReminder = false;

    let passportData = [];
    if (
      this.props &&
      this.props.partnerProfileData.passports &&
      this.props.partnerProfileData.passports.length > 0
    ) {
      for (var i = 0; i < this.props.partnerProfileData.passports.length; i++) {
        let passportValues = {
          id: this.props.partnerProfileData.passports[i].id,
          clientId: this.props.partnerProfileData.passports[i].clientId,
          passportNo:
            i === 0 ? values.passportNo || "" : values.secondPassportNo || "",
          passportCountry:
            i === 0
              ? parseInt(values.passportCountry) || 0
              : parseInt(values.secondPassportCountry) || 0,
          passportIssueDate:
            i === 0
              ? values.passportIssueDate
                ? moment(values.passportIssueDate).format(
                    "YYYY-MM-DDT00:00:00+00:00"
                  )
                : "1900-01-01T00:00:00+00:00"
              : values.secondPassportIssueDate
              ? moment(values.secondPassportIssueDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
          passportExpiryDate:
            i === 0
              ? values.passportExpiryDate
                ? moment(values.passportExpiryDate).format(
                    "YYYY-MM-DDT00:00:00+00:00"
                  )
                : "1900-01-01T00:00:00+00:00"
              : values.secondPassportExpiryDate
              ? moment(values.secondPassportExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
          passportType: i === 0 ? 1 : 0,
        };
        var expDate = moment(passportValues.passportExpiryDate).format(
          "DD/MM/YYYY"
        );
        var oldDate = moment(
          this.props.partnerProfileData.passports[i].passportExpiryDate
        ).format("DD/MM/YYYY");
        if (i == 0 && expDate != oldDate && expDate != "01/01/1900") {
          setReminder = true;
        }
        if (i == 1 && expDate != oldDate && expDate != "01/01/1900") {
          setSecondReminder = true;
        }
        passportData.push(passportValues);
      }
    } else {
      passportData = [
        {
          id: 0,
          passportNo: values.passportNo || "",
          passportCountry: values.passportCountry || 0,
          passportType: 1,
          passportIssueDate: values.passportIssueDate
            ? moment(values.passportIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.passportExpiryDate
            ? moment(values.passportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          clientId: sessionStorage.getItem("clientprofileid"),
        },
        {
          id: 0,
          passportNo: values.secondPassportNo || "",
          passportCountry: values.secondPassportCountry || 0,
          passportType: 0,
          passportIssueDate: values.secondPassportIssueDate
            ? moment(values.secondPassportIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.secondPassportExpiryDate
            ? moment(values.secondPassportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          clientId: sessionStorage.getItem("clientprofileid"),
        },
      ];
    }
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;
    let clientData = {
      clientId: clientId,
      familyId: familyId,
    };
    let cpData = this.props.partnerProfileData;
    cpData.customFields = values.customFields || [];
    cpData.clientEmails = cpData.emails;
    cpData.clientPhones = cpData.phones;
    cpData.clientMedical = cpData.medicals.length > 0 ? cpData.medicals[0] : {};
    cpData.policeCertificates = cpData.certificates;
    cpData.passports = passportData;
    this.props
      .onUpdClientPassport(passportData)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success("Partner passport detail updated successfully");
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Partner " +
            this.props.partnerProfileData.firstName +
            " " +
            this.props.partnerProfileData.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        this.props
          .onUpdatePartner(cpData)
          .then(() => {
            this.setState({ updLoading: false, loadPartner: false });
            this.props.onGetPartner(clientData);
          })
          .catch(() => {
            this.setState({ updLoading: false, loadPartner: false });
          });
        if (setReminder) {
          let myData = {
            title: "Passport Expiry",
            reminderDate: values.passportExpiryDate
              ? moment(values.passportExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 7,
          };
          AddAutoReminder(myData);
        }
        if (setSecondReminder) {
          let myDataSecond = {
            title: "Passport Expiry",
            reminderDate: values.secondPassportExpiryDate
              ? moment(values.secondPassportExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 7,
          };
          AddAutoReminder(myDataSecond);
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Partner INZ login detail update failed");
        this.props.onGetPartner(clientData);
      });
  };

  onUpdateBillingAddress = (values) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    let addressData = [];
    if (this.props.partnerProfileData.addresses.length > 0) {
      for (var i = 0; i < this.props.partnerProfileData.addresses.length; i++) {
        let addressValues = {
          id: this.props.partnerProfileData.addresses[i].id,
          clientId: this.props.partnerProfileData.addresses[i].clientId,
          contactPerson:
            this.props.partnerProfileData.addresses[i].addressTypeId === 1
              ? this.props.partnerProfileData.addresses[i].contactPerson
              : values.contactPerson,
          flat:
            this.props.partnerProfileData.addresses[i].addressTypeId === 1
              ? this.props.partnerProfileData.addresses[i].flat
              : values.flat,
          building: this.props.partnerProfileData.addresses[i].building,
          streetName: this.props.partnerProfileData.addresses[i].streetName,
          suburb:
            this.props.partnerProfileData.addresses[i].addressTypeId === 1
              ? this.props.partnerProfileData.addresses[i].suburb
              : values.suburb,
          streetNumber:
            this.props.partnerProfileData.addresses[i].addressTypeId === 1
              ? this.props.partnerProfileData.addresses[i].streetNumber
              : values.streetNumber,
          city:
            this.props.partnerProfileData.addresses[i].addressTypeId === 1
              ? this.props.partnerProfileData.addresses[i].city
              : values.city,
          state: this.props.partnerProfileData.addresses[i].state,
          zip:
            this.props.partnerProfileData.addresses[i].addressTypeId === 1
              ? this.props.partnerProfileData.addresses[i].zip
              : values.zipcode,
          country:
            this.props.partnerProfileData.addresses[i].addressTypeId === 1
              ? this.props.partnerProfileData.addresses[i].country
              : values.billCountry || 0,
          addressTypeId: this.props.partnerProfileData.addresses[i]
            .addressTypeId,
        };
        addressData.push(addressValues);
      }
    } else {
      let addressesAdd = [
        {
          id: 0,
          clientId: sessionStorage.getItem("clientprofileid"),
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 1,
        },
        {
          id: 0,
          clientId: sessionStorage.getItem("clientprofileid"),
          contactPerson: values.contactPerson || "",
          flat: values.flat || "",
          building: "",
          streetName: "",
          suburb: values.suburb || "",
          streetNumber: values.streetNumber || "",
          city: values.city || "",
          state: "",
          zip: values.zipcode || "",
          country: values.billCountry ? parseInt(values.billCountry) : 0,
          addressTypeId: 5,
        },
      ];
      addressData = [...addressesAdd];
    }
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;
    let clientData = {
      clientId: clientId,
      familyId: familyId,
    };
    let cpData = this.props.partnerProfileData;
    cpData.customFields = values.customFields || [];
    cpData.clientEmails = cpData.emails;
    cpData.clientPhones = cpData.phones;
    cpData.clientMedical = cpData.medicals.length > 0 ? cpData.medicals[0] : {};
    cpData.policeCertificates = cpData.certificates;
    cpData.addresses = addressData;
    this.props
      .onUpdClientAddress(addressData)
      .then(() => {
        message.success("Partner billing address updated successfully");
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Partner " +
            this.props.partnerProfileData.firstName +
            " " +
            this.props.partnerProfileData.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        this.props
          .onUpdatePartner(cpData)
          .then(() => {
            this.setState({ updLoading: false, loadPartner: false });
            this.props.onGetPartner(clientData);
          })
          .catch(() => {
            this.setState({ updLoading: false, loadPartner: false });
          });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Partner billing address update failed");
        this.props.onGetPartner(clientData);
      });
  };

  uploadImage = (info, id) => {
    this.setState({ loadUploadImage: true });
    if (id) {
      this.setState({ imageUpdateId: id });
    }

    let formData = new FormData();
    formData.append("File", info);
    this.props.onUploadAvatar(formData).then(() => {
      if (this.props.imageUploadSuccess) {
        this.setState({
          addClientImageUrl: this.props.imageUploadSuccess,
          loadUploadImage: false,
        });
      }
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      if (profileData) {
        let myData = {
          clientName: profileData.fullName,
          logMessage: "Partner profile picture uploaded by " + userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Partner Information",
          invoiceId: "0",
        };
        activityData(myData);
      }
    });
  };

  handleChangeImage = (info) => {
    console.log("show file data ====== ", info);
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  onAddPartner = (values) => {
    this.setState({ loadPartner: true });
    let userId = localStorage.getItem("userId");

    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    var dateOfBirth = "";
    if (values.dateOfBirth) {
      dateOfBirth = moment(values.dateOfBirth).format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
    }

    let getFamilyId = sessionStorage.getItem("familyId");
    let data = {
      clientNumber: values.clientNumber || "",
      contactPerson: "",
      familyId: getFamilyId,
      processingGroupId: 0,
      agentId: values.agentId || "00000000-0000-0000-0000-000000000000",
      clientTag: 0,
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      middleName: values.middleName || "",
      title: values.title || "",
      gender: parseInt(values.gender) || 0,
      dateOfBirth: dateOfBirth || "1900-01-01T00:00:00+00:00",
      maritalStatus: values.maritalStatus || "",
      dependentChildren: parseInt(values.dependentChildren) || 0,
      notes: this.state.contentNotes,
      occupation: values.occupation || "",
      occupationOrganization: "",
      inzUserName: values.inzUserName || "",
      inzPassword: values.inzPassword || "",
      imageBlobUrl: this.state.imageBlobUrl || "",
      nationalityId: values.nationalityId || "",
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : "",
      skypeID: "",
      preferredName: "",
      isSubscribed: false,
      arbitaryJson: "",
      dependentClientIds: "",
      currentVisaTypeId: parseInt(values.currentVisaTypeId) || 0,
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? moment(values.currentNewZealandVisaExpiry).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      travelConditionsValidTo: values.travelConditionsValidTo
        ? moment(values.travelConditionsValidTo).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      visaText: values.visaText || "",
      visaDenied: values.visaDenied || false,
      deniedText: values.deniedText || "",
      clientNumberIZM: "",
      inzFeeDate: values.inzFeeDate
        ? moment(values.inzFeeDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      interestedVisa: parseInt(values.interestedVisa) || 0,
      memberType: "Spouse",
      clientId: clientProfileIdMain,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? moment(values.nzqaOnlineSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? moment(values.nzqaDocumentSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? moment(values.nzqaDocumentRetDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      visaCountryId: parseInt(values.visaCountryId) || 0,
      visaCountyType: parseInt(values.visaCountryType) || 0,
      age: 0,
      jobSectorId: parseInt(values.jobSectorId) || 0,
      sourceId: parseInt(values.sourceId) || 0,
      sourceDescription: values.sourceDescription || "",
      clientSerial: values.clientSerial || "",
      companyOptional: values.companyOptional || "",
      dealWorth: values.dealWorth || "",
      saleDate: values.saleDate
        ? moment(values.saleDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      clientPermission: {
        signed: false,
        onshore: true,
        active: true,
        allowUpdate: false,
        areaAccess: false,
      },
      clientMedical: {
        clientId: "00000000-0000-0000-0000-000000000000",
        er: values.er || "",
        medicalIssueDate: values.medicalIssueDate
          ? moment(values.medicalIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        medicalExpiryDate: values.medicalExpiryDate
          ? moment(values.medicalExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        xrayIssueDate: values.xrayIssueDate
          ? moment(values.xrayIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        xrayExpiryDate: values.xrayExpiryDate
          ? moment(values.xrayExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        medicalGrading: values.medicalGrading || "",
        xrayGrading: values.xrayGrading || "",
        medicalNotes: values.medicalNotes || "",
        medicalNotesDetail: this.state.medicalNotes || "",
      },
      passports: [
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          passportNo: values.passportNo || "",
          passportCountry: parseInt(values.passportCountry) || 0,
          passportType: 1,
          passportIssueDate: values.passportIssueDate
            ? moment(values.dateOfBirth).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.passportExpiryDate
            ? moment(values.passportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          passportNo: values.secondPassportNo || "",
          passportCountry: parseInt(values.secondPassportCountry) || 0,
          passportType: 0,
          passportIssueDate: values.secondPassportIssueDate
            ? moment(values.secondPassportIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.secondPassportExpiryDate
            ? moment(values.secondPassportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
        },
      ],
      clientEmails: [
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          address: values.email || "",
          emailTypeId: 1,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          address: values.secondaryEmail || "",
          emailTypeId: 2,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          address: values.otherEmail || "",
          emailTypeId: 3,
        },
      ],
      addresses: [
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: values.address || "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 1,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contactPerson: values.contactPerson || "",
          flat: values.flat || "",
          building: "",
          streetName: "",
          suburb: values.suburb || "",
          streetNumber: values.streetNumber || "",
          city: values.city || "",
          state: "",
          zip: values.zipcode || "",
          country: parseInt(values.billCountry) || 0,
          addressTypeId: 5,
        },
      ],
      clientPhones: [
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.mobilePhone ? values.mobilePhone.toString() : "",
          phoneTypeId: 1,

          countryCodeId: values.countryCodeId || 168,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.secondaryMobile
            ? values.secondaryMobile.toString()
            : "",
          phoneTypeId: 2,

          countryCodeId: 168,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.overseasMobile
            ? values.overseasMobile.toString()
            : "",
          phoneTypeId: 3,

          countryCodeId: 168,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.landLine ? values.landLine.toString() : "",
          phoneTypeId: 4,

          countryCodeId: 168,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.otherMobile ? values.otherMobile.toString() : "",
          phoneTypeId: 5,

          countryCodeId: 168,
        },
      ],
      policeCertificates: [],
    };

    this.props
      .onAddPartner(data)
      .then((res) => {
        let clientprofileidMain = sessionStorage.getItem("clientProfileIdMain");
        let data = {
          clientId: clientprofileidMain,
          familyId: getFamilyId,
        };
        message.success("Partner added successfully!");
        this.setState({ addPartner: false, loadPartner: false });
        this.props.onGetPartner(data).then((resp) => {
          this.setProfileData(resp.payload);
        });
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Client partner named as " +
              values.firstName +
              " " +
              values.lastName +
              " added by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Information",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch(() => {
        message.error("Partner failed to add!");
        this.setState({ loadPartner: false });
      });
  };

  handleChangeUploadCreate = ({ fileList }) => {
    let _type =
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].type;

    if (
      _type === "image/jpeg" ||
      _type === "image/jpg" ||
      _type === "image/png" ||
      _type === undefined
    ) {
      this.setState({ fileList }, () => {
        let formData = new FormData();
        let selectedFile = fileList && fileList[0] && fileList[0].originFileObj;
        formData.append("File", selectedFile);
        if (_type !== undefined) {
          // this.setState({ loading: true });
        }
        if (selectedFile) {
          this.props.onUploadAvatar(formData).then(() => {
            if (this.props.imageUploadSuccess) {
              this.setState({
                imageBlobUrl: this.props.imageUploadSuccess,
              });
            }
          });
        }
      });
    } else {
      message.warning("Only 'png','jpg' & 'jpeg' type of images are allowed");
    }
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  showAddPartnerForm = () => {
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    if (clientProfileIdMain) {
      this.setState({ addPartner: true });
    } else {
      message.error("Please search and select client first!");
    }
  };

  onProfileTopUpdate = (toggleName) => {
    this.setState({ loadPartner: true });
    const userId = localStorage.getItem("userId");
    let data = {
      id: this.props.partnerProfileData.id,
      clientNumber: this.props.partnerProfileData.clientNumber,
      familyId: this.props.partnerProfileData.familyId,
      processingGroupId: 0,
      agentId: this.props.partnerProfileData.agentId,
      clientTag: this.props.partnerProfileData.clientTag,
      firstName: this.props.partnerProfileData.firstName,
      lastName: this.props.partnerProfileData.lastName,
      middleName: this.props.partnerProfileData.middleName,
      title: this.props.partnerProfileData.title,
      gender: this.props.partnerProfileData.gender,
      dateOfBirth: this.props.partnerProfileData.dateOfBirth,
      maritalStatus: this.props.partnerProfileData.maritalStatus,
      dependentChildren: this.props.partnerProfileData.dependentChildren,
      notes: this.props.partnerProfileData.notes,
      occupation: this.props.partnerProfileData.occupation,
      occupationOrganization: this.props.partnerProfileData
        .occupationOrganization,
      inzUserName: this.props.partnerProfileData.inzUserName,
      inzPassword: this.props.partnerProfileData.inzPassword,
      imageBlobUrl: this.state.addClientImageUrl
        ? this.state.addClientImageUrl
        : "",
      nationalityId: this.props.partnerProfileData.nationalityId,
      nationalityCountry: this.props.partnerProfileData.nationalityCountry,
      skypeID: this.props.partnerProfileData.skypeID || "",
      preferredName: this.props.partnerProfileData.preferredName,
      isSubscribed: this.props.partnerProfileData.isSubscribed,
      arbitaryJson: this.props.partnerProfileData.arbitaryJson,
      dependentClientIds: this.props.partnerProfileData.dependentClientIds,
      currentVisaTypeId: parseInt(
        this.props.partnerProfileData.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: this.props.partnerProfileData
        .currentNewZealandVisaExpiry,
      travelConditionsValidTo: this.props.partnerProfileData
        .travelConditionsValidTo,
      visaText: this.props.partnerProfileData.visaText,
      visaDenied: this.props.partnerProfileData.visaDenied,
      deniedText: this.props.partnerProfileData.deniedText,
      clientNumberIZM: this.props.partnerProfileData.clientNumberIZM,
      inzFeeDate: this.props.partnerProfileData.inzFeeDate,
      interestedVisa: this.props.partnerProfileData.interestedVisa || 0,
      memberType: this.props.partnerProfileData.memberType,
      clientId: this.props.partnerProfileData.clientId,
      nzqaOnlineSubDate: this.props.partnerProfileData.nzqaOnlineSubDate,
      nzqaDocumentSubDate: this.props.partnerProfileData.nzqaDocumentSubDate,
      nzqaDocumentRetDate: this.props.partnerProfileData.nzqaDocumentRetDate,
      visaCountryId: parseInt(this.props.partnerProfileData.visaCountryId),
      visaCountyType: parseInt(this.props.partnerProfileData.visaCountyType),
      age: this.props.partnerProfileData.age,
      jobSectorId: parseInt(this.props.partnerProfileData.jobSectorId),
      sourceId: parseInt(this.props.partnerProfileData.sourceId),
      sourceDescription: this.props.partnerProfileData.sourceDescription,
      clientSerial: this.props.partnerProfileData.clientSerial,
      companyOptional: this.props.partnerProfileData.companyOptional,
      dealWorth: this.props.partnerProfileData.dealWorth,
      saleDate: this.props.partnerProfileData.saleDate,
      agentUserId:
        this.props.partnerProfileData.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      customFields: this.props.partnerProfileData.customFields || [],
      clientPermission: {
        signed: this.state.signed,
        onshore: this.state.onshore,
        active: this.state.active,
        allowUpdate: this.state.allowUpdate,
        areaAccess: this.state.areaAccess,
      },
      addresses: this.props.partnerProfileData.addresses,
      clientEmails: this.props.partnerProfileData.emails,
      clientPhones: this.props.partnerProfileData.phones,
      clientMedical:
        this.props.partnerProfileData.medicals &&
        this.props.partnerProfileData.medicals[0],
      passports: this.props.partnerProfileData.passports,
      policeCertificates: this.props.partnerProfileData.certificates,
    };

    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false });
        message.success("Profile updated successfully");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;

        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props
          .onGetPartner(data)
          .then(() => {
            this.setState({ loadPartner: false });
          })
          .catch(() => {
            this.setState({ loadPartner: false });
          });
        if (toggleName === "onActive") {
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          let active_inActive = this.state.active ? "Active" : "Inactive";
          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                "Client status updated to " +
                active_inActive +
                " by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Information",
              invoiceId: "0",
            };
            activityData(myData);
          }
        }
        if (toggleName === "onSigned" && !this.state.signed) {
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          let signed_unSigned = this.state.signed ? "enabled" : "disabled";
          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                "Client contract SIGNED status updated as " +
                signed_unSigned +
                " by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Information",
              invoiceId: "0",
            };
            activityData(myData);
          }
        }
        if (toggleName === "onShore") {
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          let onshore_offShore = this.state.onshore ? "Onshore" : "Offshore";
          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                "Client status updated to " +
                onshore_offShore +
                " by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Information",
              invoiceId: "0",
            };
            activityData(myData);
          }
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Profile update failed");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;

        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetPartner(data);
      });
  };

  onGetPartner = () => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;

    let data = {
      clientId: clientId,
      familyId: familyId,
    };
    this.props.onGetPartner(data);
  };

  onFinishSigned = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.setState({ loadPartner: true });
    let data = {
      id: clientprofileid,
      lastAgreementSigned: values.sigedAgreementDate,
    };

    this.props
      .onUpdateSignedDate(data)
      .then((res) => {
        this.setState({ signed: true, signedModalVisible: false });
        this.setState({ loadPartner: false });
        this.onProfileTopUpdate();
        this.formRef.current.resetFields();
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        let signed_unSigned = this.state.signed ? "enabled" : "disabled";
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Client contract SIGNED status " +
              moment(values.sigedAgreementDate).format("DD/MM/YYYY") +
              " " +
              signed_unSigned +
              " by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Information",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((err) => {
        this.setState({ loadPartner: false });
      });
  };

  downlaodImage = () => {
    if (this.state.addClientImageUrl) {
      saveAs(this.state.addClientImageUrl, "image.jpg"); // Put your image url here.
    } else {
      message.warning("Image not attached with this profile!");
    }
  };

  showHideContainers = (data) => {
    let contObjList = [...this.state.showContList];
    if (contObjList.length > 0) {
      let findContObjIndex = contObjList.findIndex(
        (obj) => obj.type === data.type
      );
      if (findContObjIndex > -1) {
        contObjList[findContObjIndex].status = data.status;
      } else {
        contObjList.push(data);
      }
    } else {
      contObjList.push(data);
    }
    this.setState({ loadClient: true });
    const updClientProfileSetOpt = {
      url: `v1/users/ClientProfileSetting`,
    };
    updClientProfileSetOpt.types = [
      "UPD_CLIENT_PROFILE_SETTING_SUCCESS",
      "UPD_CLIENT_PROFILE_SETTING_FAILURE",
    ];
    apiRefresh
      .put(updClientProfileSetOpt, contObjList)
      .then((res) => {
        this.setState({ loadClient: false });
        this.getProfileSettings();
        this.props.onGetClientProfile(
          sessionStorage.getItem("clientprofileid")
        );
      })
      .catch((err) => {
        this.setState({ loadClient: false });
      });
  };

  render() {
    const {
      selectedOption,
      signed,
      onshore,
      active,
      allowUpdate,
      areaAccess,
      updLoading,
      addPartner,
      addClientImageUrl,
      fileList,
      previewVisible,
      previewImage,
      previewTitle,
      loadPartner,
      headerOptions,
      signedModalVisible,
      showContList,
      branchProfileSetting,
    } = this.state;
    const {
      partnerProfileData,
      countriesData,
      groupsData,
      accessingAuthData,
      groupMembersData,
      visaTypeData,
      visaStatusData,
      onUpdateCaseStatus,
      onGetVisaApplication,
      visaAppData,
      employerJobHistoryCurrentRes,
      onSetActiveKey,
      activeKey,
      teamMembers,
      clientSourceListing,
      onUpdatePriority,
      visaPriorityRes,
      onSignedVisa,
      onPaidVisa,
      remindersRes,
      onGetClientTask,
      onUpdateTask,
      onGetAllUsers,
      onAddTaskFollower,
      onRemoveTasks,
      onAddDailyTasks,
      onGetClientFamily,
      onUpdateCompletedTask,
      onAddTaskFileNote,
      onAddTaskComment,
      onGetTaskComments,
      onGetTaskFollowers,
      onGetVisaStatus,
      onGetReminder,
      onGetAdmissionProgram,
      onGetAdmissionStatuses,
      onGetVisaType,
      onGetVisaTypeByCountry,
      brnchVisaCountriesData,
      agentsRes,
    } = this.props;
    console.log("accessingAuthData data", visaTypeData);
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div>
        <Spin spinning={loadPartner}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              {headerOptions.length > 0 && (
                <HeaderBarTabs
                  data={headerOptions}
                  activeTab="Partner Detail"
                />
              )}
              <ProfileTopBarTabs
                data={topBar}
                partnerId={partnerProfileData && partnerProfileData.id}
                activeTab="CLIENT INFORMATION"
              />
              <div style={{ display: "flex", margin: 10 }}>
                <div
                  className={
                    activeKey
                      ? "content-width-open-sidebar"
                      : "content-width-close-sidebar"
                  }
                >
                  <Modal
                    className="reminder-model-main width-modal-outgoing-payments"
                    title="AGREEMENT SIGNED"
                    visible={signedModalVisible}
                    onCancel={() =>
                      this.setState({ signedModalVisible: false })
                    }
                    footer={null}
                    maskClosable={false}
                  >
                    <Form onFinish={this.onFinishSigned} ref={this.formRef}>
                      <div
                        className="form-container"
                        style={{
                          display: "flex",
                          marginTop: 10,
                          justifyContent: "space-between",
                          padding: 20,
                          alignItems: "center",
                        }}
                      >
                        <p className="medical-label">Date</p>
                        <div
                          style={{
                            display: "flex",
                            border: "none",
                            width: "48%",
                          }}
                        >
                          <Form.Item
                            name="sigedAgreementDate"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Required!",
                              },
                            ]}
                          >
                            <DatePicker format={dateFormat} />
                          </Form.Item>
                        </div>
                      </div>
                      <Row>
                        <Col xs={4} offset={18} style={{ display: "flex" }}>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{ marginRight: "10px" }}
                            >
                              Save
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              onClick={() =>
                                this.setState({ signedModalVisible: false })
                              }
                            >
                              Close
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Modal>
                  {!partnerProfileData && !addPartner && (
                    <div
                      className="profile-additional-box"
                      style={{ marginTop: 42 }}
                    >
                      <div style={{ padding: 20, paddingBottom: 10 }}>
                        <Button
                          loading={updLoading}
                          className="add-partner-btn"
                          onClick={this.showAddPartnerForm}
                        >
                          <span
                            style={{
                              color: "#FFFFFF",
                              fontSize: 15,
                              fontWeight: "500",
                            }}
                          >
                            ADD PARTNER
                          </span>
                        </Button>
                      </div>
                    </div>
                  )}
                  {(partnerProfileData || addPartner) && (
                    <div style={{ width: "100%" }}>
                      {!addPartner && (
                        <div
                          className="profile-first-box"
                          style={{ marginTop: 45 }}
                        >
                          <div>
                            <div
                              style={{ flexDirection: "row", display: "flex" }}
                            >
                              <div className="profile-cont-left">
                                <div className="profile-img-cont ant-upload-profile">
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader ant-upload-profile"
                                    showUploadList={false}
                                    action={this.uploadImage}
                                    onChange={this.handleChangeImage}
                                  >
                                    <Spin spinning={this.state.loadUploadImage}>
                                      {addClientImageUrl ? (
                                        <img
                                          src={addClientImageUrl}
                                          alt="avatar"
                                          style={{ width: 105, height: 105 }}
                                        />
                                      ) : (
                                        <img
                                          src={Images.dummyUserImage}
                                          className="profile-img"
                                        />
                                      )}
                                    </Spin>
                                  </Upload>
                                </div>
                                <h3
                                  style={{
                                    textAlign: "center",
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                  }}
                                >
                                  {partnerProfileData
                                    ? partnerProfileData.firstName +
                                      " " +
                                      partnerProfileData.lastName
                                    : ""}
                                </h3>
                                <h5>
                                  {!partnerProfileData
                                    ? ""
                                    : partnerProfileData.visaCountyType === 1
                                    ? "STUDENT"
                                    : partnerProfileData.visaCountyType === 2
                                    ? "VISA"
                                    : partnerProfileData.visaCountyType === 3 &&
                                      "UNSUCCESSFULL"}
                                </h5>
                                <div style={{ display: "flex", marginTop: 15 }}>
                                  <Tooltip
                                    title="Save Profile Picture"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        marginRight: 5,
                                      }}
                                      onClick={() => {
                                        this.onProfileTopUpdate();
                                      }}
                                    >
                                      <CheckOutlined
                                        style={{ color: "#56B1F9" }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title="Remove Profile Picture"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.setState({
                                          addClientImageUrl: "",
                                        });
                                        setTimeout(() => {
                                          this.onProfileTopUpdate();
                                        }, 500);
                                      }}
                                    >
                                      <img
                                        src={Images.cross}
                                        style={{ width: 13, height: 13 }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title="Download Profile Picture"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div
                                      style={{
                                        marginLeft: 10,
                                        cursor: "pointer",
                                      }}
                                      onClick={this.downlaodImage}
                                    >
                                      <img
                                        src={Images.download}
                                        className="svg-img"
                                      />
                                    </div>
                                  </Tooltip>
                                  {/* <div style={{ marginLeft: 10 }}>
                                    <img
                                      src={Images.multimediaOption}
                                      className="svg-img"
                                    />
                                  </div> */}
                                </div>
                              </div>
                              <div className="right-cont">
                                <div className="profile-puq-cont">
                                  <div
                                    className="profile-print-box"
                                    style={{ width: 26, height: 26 }}
                                  >
                                    <img
                                      src={Images.printWhite}
                                      className="profile-print-icon"
                                    />
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      className="profile-updbtn-cont"
                                      style={{
                                        marginLeft: 5,
                                        backgroundColor: "#0F7EB6",
                                        border: 1,
                                        borderStyle: "solid",
                                        borderColor: "#0F7EB6",
                                        height: 26,
                                      }}
                                    >
                                      <span className="profile-updbtn-text">
                                        QUESTIONNAIRE
                                      </span>
                                      <img
                                        src={Images.rightArrow}
                                        style={{
                                          transform: `rotate(90deg)`,
                                          width: 10,
                                          height: 10,
                                          marginLeft: 3,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="date-div">
                                  <div>
                                    <span className="date-text">
                                      Created On:{" "}
                                      {partnerProfileData
                                        ? moment(
                                            partnerProfileData.createdDate
                                          ).format("DD/MM/YYYY")
                                        : ""}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="date-text">
                                      Modified On:{" "}
                                      {partnerProfileData &&
                                        moment(
                                          partnerProfileData.modifiedDate
                                        ).format("DD/MM/YYYY")}
                                    </span>
                                  </div>
                                </div>
                                <div className="lv-main-cont">
                                  <div className="label-value-cont">
                                    <div className="label-cont">
                                      <span className="label-text">EZM ID</span>
                                    </div>
                                    <div className="value-cont">
                                      <span className="value-text">
                                        {partnerProfileData
                                          ? partnerProfileData.clientNumberIZM
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="label-value-cont">
                                    <div className="label-cont">
                                      <span className="label-text">
                                        Client ID
                                      </span>
                                    </div>
                                    <div className="value-cont">
                                      <span className="value-text">
                                        {partnerProfileData
                                          ? partnerProfileData.clientNumber
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="label-value-cont">
                                    <div
                                      className="label-cont"
                                      style={{ marginLeft: 0.5 }}
                                    >
                                      <span className="label-text">DOB</span>
                                    </div>
                                    <div className="value-cont">
                                      <span className="value-text">
                                        {partnerProfileData &&
                                        partnerProfileData.dateOfBirth !==
                                          "1900-01-01T00:00:00+00:00"
                                          ? moment(
                                              partnerProfileData.dateOfBirth
                                            ).format("DD/MM/YYYY")
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="buttons-row">
                                  <div>
                                    <div className="black-button">
                                      <span className="black-button-text">
                                        Signed
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        signed
                                          ? "right-green-btn-cont"
                                          : "cross-cont"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (!signed) {
                                          this.setState({
                                            signedModalVisible: true,
                                          });
                                        } else {
                                          this.setState({ signed: !signed });
                                          setTimeout(() => {
                                            this.onProfileTopUpdate("onSigned");
                                          }, 500);
                                        }
                                      }}
                                    >
                                      {signed && (
                                        <img
                                          src={Images.tickWhite}
                                          className="svg-btn-img"
                                          style={{ marginRight: 10 }}
                                        />
                                      )}
                                      <img
                                        src={Images.btnImage}
                                        className="profile-btn-img"
                                      />
                                      {!signed && (
                                        <img
                                          src={Images.crossWhite}
                                          className="svg-btn-img"
                                          style={{ marginLeft: 10 }}
                                        />
                                      )}
                                    </div>
                                    <div></div>
                                  </div>
                                  <div style={{ marginLeft: 10 }}>
                                    <div className="black-button">
                                      <span className="black-button-text">
                                        Onshore
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        onshore
                                          ? "right-green-btn-cont"
                                          : "cross-cont"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.setState({ onshore: !onshore });
                                        setTimeout(() => {
                                          this.onProfileTopUpdate("onShore");
                                        }, 500);
                                      }}
                                    >
                                      {onshore && (
                                        <img
                                          src={Images.tickWhite}
                                          className="svg-btn-img"
                                          style={{ marginRight: 10 }}
                                        />
                                      )}
                                      <img
                                        src={Images.btnImage}
                                        className="profile-btn-img"
                                      />
                                      {!onshore && (
                                        <img
                                          src={Images.crossWhite}
                                          className="svg-btn-img"
                                          style={{ marginLeft: 10 }}
                                        />
                                      )}
                                    </div>
                                    <div></div>
                                  </div>
                                  <div style={{ marginLeft: 10 }}>
                                    <div className="black-button">
                                      <span className="black-button-text">
                                        Active
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        active
                                          ? "right-green-btn-cont"
                                          : "cross-cont"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.setState({ active: !active });
                                        setTimeout(() => {
                                          this.onProfileTopUpdate("onActive");
                                        }, 500);
                                      }}
                                    >
                                      {active && (
                                        <img
                                          src={Images.tickWhite}
                                          className="svg-btn-img"
                                          style={{ marginRight: 10 }}
                                        />
                                      )}
                                      <img
                                        src={Images.btnImage}
                                        className="profile-btn-img"
                                      />
                                      {!active && (
                                        <img
                                          src={Images.crossWhite}
                                          className="svg-btn-img"
                                          style={{ marginLeft: 10 }}
                                        />
                                      )}
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                                {/* <div style={{marginLeft: 20}}>
                                  <span style={{ fontSize: 12 }}>
                                    Agreement Signed Date:{" "}
                                    <span className="tag-text" style={{ fontSize: 11}}>
                                      {moment(
                                        partnerProfileData.lastAgreementSigned
                                      ).format("DD/MM/YYYY")}
                                    </span>
                                  </span>
                                </div> */}
                                {false && partnerProfileData.agentName && (
                                  <div>
                                    <div className="agent-tag-cont">
                                      <div className="agent-tag">
                                        <img
                                          src={crossGreen}
                                          style={{ width: 8, height: 8 }}
                                        />
                                        <span className="tag-text">
                                          Agent Name:{" "}
                                          {partnerProfileData.agentName}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {addPartner ? (
                        <Form onFinish={this.onAddPartner}>
                          <div
                            className="profile-additional-box"
                            style={{
                              paddingBottom: 50,
                              marginTop: addPartner ? 40 : 20,
                              marginLeft: 30,
                            }}
                          >
                            <div style={{ marginLeft: 30, paddingTop: 30 }}>
                              <Upload
                                listType="picture-card"
                                fileList={fileList}
                                beforeUpload={() => false}
                                onPreview={this.handlePreview}
                                action={this.uploadImage}
                                onChange={this.handleChangeUploadCreate}
                              >
                                {fileList && fileList.length > 0
                                  ? null
                                  : uploadButton}
                              </Upload>
                              <Modal
                                visible={previewVisible}
                                title={previewTitle}
                                footer={null}
                                onCancel={this.handleCancel}
                              >
                                <img
                                  alt="example"
                                  style={{ width: "100%" }}
                                  src={previewImage}
                                />
                              </Modal>
                            </div>
                            <PersonalInformationAddPartner
                              clientInfo={partnerProfileData}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updatePersonalInfo={() =>
                                console.log("personal info")
                              }
                              personsData={teamMembers}
                              clientSourceListing={clientSourceListing}
                              onChangeContent={(value) =>
                                this.setState({ contentNotes: value })
                              }
                              brnchVisaCountriesData={brnchVisaCountriesData}
                              users={this.state.users}
                            />
                            <MedicalsAddPartner
                              clientInfo={partnerProfileData}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileMedicals={() =>
                                console.log("medical")
                              }
                              onChangeContent={(value) =>
                                this.setState({ medicalNotes: value })
                              }
                            />
                            <PassportAddPartner
                              clientInfo={partnerProfileData}
                              countriesData={countriesData}
                              onAddCertificate={this.addCertificate}
                              onAddAuthority={this.addAuthority}
                              onRemoveCertificate={this.removeCertificate}
                              accessingAuthData={accessingAuthData}
                              visaTypeData={visaTypeData}
                              updateProfilePassport={() =>
                                console.log("passport")
                              }
                            />
                            <InzLoginAddPartner
                              clientInfo={partnerProfileData}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileInzDetail={() =>
                                console.log("inz login")
                              }
                              isClient={false}
                            />
                            <NZQADetailAddPartner
                              clientInfo={partnerProfileData}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileNZQA={() => console.log("nzqa")}
                            />
                            <BillingAddressAddPartner
                              clientInfo={partnerProfileData}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileBillingAddress={() =>
                                console.log("Bill")
                              }
                            />
                            <Form.Item
                              style={{ marginLeft: 30, marginTop: 20 }}
                            >
                              <Button className="button-blue" htmlType="submit">
                                <span style={{ color: "#FFFFFF" }}>Save</span>
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      ) : (
                        <>
                          <div
                            className="profile-additional-box"
                            style={{
                              paddingBottom: 50,
                              marginTop: addPartner ? 40 : 20,
                            }}
                          >
                            {branchProfileSetting &&
                            branchProfileSetting.length > 0 &&
                            !branchProfileSetting.find(
                              (obj) => obj.type === "Personal Information"
                            ).status ? (
                              showContList.length > 0 &&
                              showContList.find(
                                (obj) => obj.type === "personal_information"
                              ) &&
                              showContList.find(
                                (obj) => obj.type === "personal_information"
                              ).status ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginRight: 10,
                                    padding: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="profile-down-arrow-cont"
                                      style={{
                                        marginTop: 5,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.showHideContainers({
                                          type: "personal_information",
                                          status: false,
                                        })
                                      }
                                    >
                                      <img
                                        src={Images.whiteArrow}
                                        className="profile-down-arrow-icon"
                                        style={{ transform: "rotate(270deg)" }}
                                      />
                                    </div>
                                    <p className="label">
                                      Personal Information
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <PersonalInformation
                                  clientInfo={partnerProfileData}
                                  countriesData={countriesData}
                                  visaTypeData={visaTypeData}
                                  updatePersonalInfo={this.personalInfoUpdate}
                                  personsData={teamMembers}
                                  clientSourceListing={clientSourceListing}
                                  accessingAuthData={accessingAuthData}
                                  brnchVisaCountriesData={
                                    brnchVisaCountriesData
                                  }
                                  history={this.props.history}
                                  users={this.state.users}
                                  agentsRes={agentsRes}
                                  onGetClientProfile={this.onGetProfile}
                                  showHideContainers={this.showHideContainers}
                                />
                              )
                            ) : null}
                            {branchProfileSetting &&
                            branchProfileSetting.length > 0 &&
                            !branchProfileSetting.find(
                              (obj) => obj.type === "Current Visa Detail"
                            ).status ? (
                              showContList.length > 0 &&
                              showContList.find(
                                (obj) => obj.type === "current_visa_detail"
                              ) &&
                              showContList.find(
                                (obj) => obj.type === "current_visa_detail"
                              ).status ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginRight: 10,
                                    padding: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="Show Container Fields">
                                      <div
                                        className="profile-down-arrow-cont"
                                        style={{
                                          marginTop: 5,
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.showHideContainers({
                                            type: "current_visa_detail",
                                            status: false,
                                          })
                                        }
                                      >
                                        <img
                                          src={Images.whiteArrow}
                                          className="profile-down-arrow-icon"
                                          style={{
                                            transform: "rotate(270deg)",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <p className="label">Current Visa Detail</p>
                                  </div>
                                </div>
                              ) : (
                                <CurrentVisa
                                  clientInfo={partnerProfileData}
                                  countriesData={countriesData}
                                  visaTypeData={visaTypeData}
                                  updateProfileCurrentVisa={
                                    this.onUpdateCurrentVisa
                                  }
                                  onGetClientProfile={this.onGetProfile}
                                  showHideContainers={this.showHideContainers}
                                />
                              )
                            ) : null}
                            {branchProfileSetting &&
                            branchProfileSetting.length > 0 &&
                            !branchProfileSetting.find(
                              (obj) => obj.type === "Medical Detail"
                            ).status ? (
                              showContList.length > 0 &&
                              showContList.find(
                                (obj) => obj.type === "medical_detail"
                              ) &&
                              showContList.find(
                                (obj) => obj.type === "medical_detail"
                              ).status ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginRight: 10,
                                    padding: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="Show Container Fields">
                                      <div
                                        className="profile-down-arrow-cont"
                                        style={{
                                          marginTop: 5,
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.showHideContainers({
                                            type: "medical_detail",
                                            status: false,
                                          })
                                        }
                                      >
                                        <img
                                          src={Images.whiteArrow}
                                          className="profile-down-arrow-icon"
                                          style={{
                                            transform: "rotate(270deg)",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <p className="label">Medical Detail</p>
                                  </div>
                                </div>
                              ) : (
                                <Medicals
                                  clientInfo={partnerProfileData}
                                  countriesData={countriesData}
                                  visaTypeData={visaTypeData}
                                  updateProfileMedicals={this.onUpdateMedicals}
                                  onGetClientProfile={this.onGetProfile}
                                  showHideContainers={this.showHideContainers}
                                />
                              )
                            ) : null}

                            <Passport
                              clientInfo={partnerProfileData}
                              countriesData={countriesData}
                              onAddCertificate={
                                this.props.onAddPoliceCertificate
                              }
                              onAddAuthority={this.addAuthority}
                              onRemoveCertificate={this.removeCertificate}
                              onRemoveAuthority={this.removeAuthority}
                              accessingAuthData={accessingAuthData}
                              visaTypeData={visaTypeData}
                              updateProfilePassport={this.onUpdatePassport}
                              onGetClientProfile={this.onGetPartner}
                              onAddReminderTask={this.props.onAddReminderTask}
                              onGetProfile={this.onGetProfile}
                              showHideContainers={this.showHideContainers}
                              showContList={showContList}
                              branchProfileSetting={branchProfileSetting}
                            />

                            {branchProfileSetting &&
                            branchProfileSetting.length > 0 &&
                            !branchProfileSetting.find(
                              (obj) => obj.type === "Login Detail"
                            ).status ? (
                              showContList.length > 0 &&
                              showContList.find(
                                (obj) => obj.type === "login_detail"
                              ) &&
                              showContList.find(
                                (obj) => obj.type === "login_detail"
                              ).status ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginRight: 10,
                                    padding: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="Show Container Fields">
                                      <div
                                        className="profile-down-arrow-cont"
                                        style={{
                                          marginTop: 5,
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.showHideContainers({
                                            type: "login_detail",
                                            status: false,
                                          })
                                        }
                                      >
                                        <img
                                          src={Images.whiteArrow}
                                          className="profile-down-arrow-icon"
                                          style={{
                                            transform: "rotate(270deg)",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <p className="label">Login Detail</p>
                                  </div>
                                </div>
                              ) : (
                                <InzLogin
                                  clientInfo={partnerProfileData}
                                  countriesData={countriesData}
                                  visaTypeData={visaTypeData}
                                  updateProfileInzDetail={
                                    this.onUpdateInzUserDetail
                                  }
                                  isClient={false}
                                  onGetClientProfile={this.onGetProfile}
                                  onGetProfile={this.onGetProfile}
                                  showHideContainers={this.showHideContainers}
                                />
                              )
                            ) : null}
                            {branchProfileSetting &&
                            branchProfileSetting.length > 0 &&
                            !branchProfileSetting.find(
                              (obj) => obj.type === "NZQA Detail"
                            ).status ? (
                              showContList.length > 0 &&
                              showContList.find(
                                (obj) => obj.type === "nzqa_detail"
                              ) &&
                              showContList.find(
                                (obj) => obj.type === "nzqa_detail"
                              ).status ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginRight: 10,
                                    padding: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="Show Container Fields">
                                      <div
                                        className="profile-down-arrow-cont"
                                        style={{
                                          marginTop: 5,
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.showHideContainers({
                                            type: "nzqa_detail",
                                            status: false,
                                          })
                                        }
                                      >
                                        <img
                                          src={Images.whiteArrow}
                                          className="profile-down-arrow-icon"
                                          style={{
                                            transform: "rotate(270deg)",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <p className="label">NZQA Detail</p>
                                  </div>
                                </div>
                              ) : (
                                <NZQADetail
                                  clientInfo={partnerProfileData}
                                  countriesData={countriesData}
                                  visaTypeData={visaTypeData}
                                  updateProfileNZQA={this.onUpdateNZQA}
                                  onGetClientProfile={this.onGetProfile}
                                  showHideContainers={this.showHideContainers}
                                />
                              )
                            ) : null}
                            {branchProfileSetting &&
                            branchProfileSetting.length > 0 &&
                            !branchProfileSetting.find(
                              (obj) => obj.type === "Billing Address"
                            ).status ? (
                              showContList.length > 0 &&
                              showContList.find(
                                (obj) => obj.type === "billing_address"
                              ) &&
                              showContList.find(
                                (obj) => obj.type === "billing_address"
                              ).status ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginRight: 10,
                                    padding: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="Show Container Fields">
                                      <div
                                        className="profile-down-arrow-cont"
                                        style={{
                                          marginTop: 5,
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.showHideContainers({
                                            type: "billing_address",
                                            status: false,
                                          })
                                        }
                                      >
                                        <img
                                          src={Images.whiteArrow}
                                          className="profile-down-arrow-icon"
                                          style={{
                                            transform: "rotate(270deg)",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <p className="label">Billing Address</p>
                                  </div>
                                </div>
                              ) : (
                                <BillingAddress
                                  clientInfo={partnerProfileData}
                                  countriesData={countriesData}
                                  visaTypeData={visaTypeData}
                                  updateProfileBillingAddress={
                                    this.onUpdateBillingAddress
                                  }
                                  onGetClientProfile={this.onGetProfile}
                                  showHideContainers={this.showHideContainers}
                                />
                              )
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {/*<div
                  style={{
                    float: "left",
                    position: "fixed",
                    left: 250,
                    bottom: 30
                  }}
                >
                    <Button
                      loading={updLoading}
                      className="button-blue"
                      htmlType="submit"
                    >
                      <span style={{ color: "#FFFFFF" }}>Update</span>
                    </Button>
                </div>*/}
                </div>
                <div
                  className=""
                  style={{
                    width: activeKey ? "438px" : "115px",
                    marginLeft: 30,
                    marginTop: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <ProfileSideBar
                        onGetClientTag={this.props.onGetClientTag}
                        clientTagRes={this.props.clientTagRes}
                        getClientRes={this.props.getClientRes}
                        onGetProfileClientTag={this.props.onGetProfileClientTag}
                        onAddProfileClientTag={this.props.onAddProfileClientTag}
                        addProfielTagRes={this.props.addProfielTagRes}
                        onRemoveProfileClientTag={
                          this.props.onRemoveProfileClientTag
                        }
                        removeProfileTagRes={this.props.removeProfileTagRes}
                        visaStatusData={visaStatusData}
                        onUpdateCaseStatus={onUpdateCaseStatus}
                        visaAppData={visaAppData}
                        onGetVisaApplication={onGetVisaApplication}
                        employerJobHistoryCurrentRes={
                          employerJobHistoryCurrentRes
                        }
                        onSetActiveKey={onSetActiveKey}
                        activeKey={activeKey}
                        onUpdatePriority={onUpdatePriority}
                        visaPriorityRes={visaPriorityRes}
                        onSignedVisa={onSignedVisa}
                        onPaidVisa={onPaidVisa}
                        remindersRes={remindersRes && remindersRes.items}
                        onGetClientTask={onGetClientTask}
                        onUpdateTask={onUpdateTask}
                        onGetAllUsers={onGetAllUsers}
                        onAddTaskFollower={onAddTaskFollower}
                        onRemoveTasks={onRemoveTasks}
                        onAddDailyTasks={onAddDailyTasks}
                        onGetClientFamily={onGetClientFamily}
                        onUpdateCompletedTask={onUpdateCompletedTask}
                        onAddTaskFileNote={onAddTaskFileNote}
                        onAddTaskComment={onAddTaskComment}
                        onGetTaskComments={onGetTaskComments}
                        onGetTaskFollowers={onGetTaskFollowers}
                        onLoadClient={loadPartner}
                        onGetReminder={onGetReminder}
                        onGetVisaStatus={onGetVisaStatus}
                        onGetAdmissionProgram={onGetAdmissionProgram}
                        onGetAdmissionStatuses={onGetAdmissionStatuses}
                        onGetVisaType={onGetVisaType}
                        onGetVisaTypeByCountry={onGetVisaTypeByCountry}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default Partner;
