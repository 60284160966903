// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
  InputNumber,
} from "antd";
import moment from "moment";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";

function PersonalInformation(Props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [interestedVisa, setInterestedVisa] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [visaDenied, setVisaDenied] = useState([]);
  const [certificateIssueDate, setCertificateIssueDate] = useState("");
  const [certificateExpiryDate, setCertificateExpiryDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [authority, setAuthority] = useState("");
  const [authSubDate, setAuthSubDate] = useState("");
  const [authRetDate, setAuthRetDate] = useState("");
  const [authRefNo, setAuthRefNo] = useState("");
  const { TextArea } = Input;

  const [selectedOption, setSelectedOption] = useState("");
  const dateFormat = "DD/MM/YYYY";

  const [form] = Form.useForm();

  const yearsDiff = (date) => {
    let date1 = new Date();
    let date2 = new Date(date);

    var ends = moment(date1);
    var starts = moment(date2);
    var years = ends.diff(starts, "year");
    starts.add(years, "years");
    var months = ends.diff(starts, "months");
    starts.add(months, "months");
    var days = ends.diff(starts, "days");
    let yearsDiff =
      years + " years, " + months + " months and " + days + " days";
    return yearsDiff;
  };

  useEffect(() => {
    if (Props.clientInfo) {
      setCertificates(Props.clientInfo.certificates);
      setAuthorities(Props.clientInfo.authorities);
      setVisaDenied(Props.clientInfo.visaDenied);
      var ageByDate = 0;
      if (Props.clientInfo.dateOfBirth !== "1900-01-01T00:00:00+00:00")
        ageByDate = yearsDiff(Props.clientInfo.dateOfBirth);
      var email = "";
      var secondaryEmail = "";
      var otherEmail = "";
      if (Props.clientInfo.emails.length > 0) {
        for (var i = 0; i < Props.clientInfo.emails.length; i++) {
          if (Props.clientInfo.emails[i].emailTypeId === 1) {
            email = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 2) {
            secondaryEmail = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 3) {
            otherEmail = Props.clientInfo.emails[i].address;
          }
        }
      }
      var billAddressData = null;
      var clientAddressData = null;
      if (Props.clientInfo.addresses.length > 0) {
        const findBillAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 5
        );
        if (findBillAddress) {
          billAddressData = {
            contactPerson: findBillAddress.contactPerson,
            flat: findBillAddress.flat,
            streetNumber: findBillAddress.streetNumber,
            suburb: findBillAddress.suburb,
            city: findBillAddress.city,
            country: findBillAddress.country,
            zipcode: findBillAddress.zip,
          };
        }

        const findAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 1
        );
        if (findAddress) {
          clientAddressData = {
            address: findAddress.city,
          };
        }
      }
      var medicalData = null;
      if (Props.clientInfo.medicals.length > 0) {
        medicalData = {
          medicalIssueDate: Props.clientInfo.medicals[0].medicalIssueDate,
          medicalExpiryDate: Props.clientInfo.medicals[0].medicalExpiryDate,
          medicalGrading: Props.clientInfo.medicals[0].medicalGrading,
          xrayIssueDate: Props.clientInfo.medicals[0].xrayIssueDate,
          xrayExpiryDate: Props.clientInfo.medicals[0].xrayExpiryDate,
          xrayGrading: Props.clientInfo.medicals[0].xrayGrading,
          medicalNotes: Props.clientInfo.medicals[0].medicalNotes,
          medicalNotesDetail: Props.clientInfo.medicals[0].medicalNotesDetail,
        };
      }
      var phoneData = {
        mobile: "",
        secondaryMobile: "",
        overseasMobile: "",
        landLine: "",
        otherMobile: "",
        countryCode: 0,
      };
      if (Props.clientInfo.phones.length > 0) {
        const findMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 1
        );
        if (findMobile) {
          phoneData.mobile = findMobile.contact;
          phoneData.countryCodeId =
            findMobile.countryCodeId !== 0 ? findMobile.countryCodeId : "";
        }
        const findSecondaryMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 2
        );
        if (findSecondaryMobile) {
          phoneData.secondaryMobile = findSecondaryMobile.contact;
        }
        const findOverseasMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 3
        );
        if (findOverseasMobile) {
          phoneData.overseasMobile = findOverseasMobile.contact;
        }
        const findLandLineMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 4
        );
        if (findLandLineMobile) {
          phoneData.landLine = findLandLineMobile.contact;
        }
        const findOtherMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 5
        );
        if (findOtherMobile) {
          phoneData.otherMobile = findOtherMobile.contact;
        }
      }
      //
      form.setFieldsValue({
        visaCountryId:
          Props.clientInfo.visaCountryId === 0
            ? ""
            : Props.clientInfo.visaCountryId.toString(),
        visaCountryType:
          Props.clientInfo.visaCountyType === 0
            ? ""
            : Props.clientInfo.visaCountyType.toString(),
        title: Props.clientInfo.title,
        middleName: Props.clientInfo.middleName,
        gender: Props.clientInfo.gender.toString(),
        dateOfBirth:
          Props.clientInfo.dateOfBirth === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.dateOfBirth),
        address: clientAddressData ? clientAddressData.address : "",
        nationalityId: Props.clientInfo.nationalityId,
        saleDate:
          Props.clientInfo.saleDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.saleDate),
        sourceId:
          Props.clientInfo.sourceId === 0
            ? ""
            : Props.clientInfo.sourceId.toString(),
        jobSectorId:
          Props.clientInfo.jobSectorId === 0
            ? ""
            : Props.clientInfo.jobSectorId.toString(),
        companyOptional: Props.clientInfo.companyOptional,
        clientSerial: Props.clientInfo.clientSerial,
        nationalityCountry:
          Props.clientInfo.nationalityCountry === 0
            ? ""
            : Props.clientInfo.nationalityCountry,
        firstName: Props.clientInfo.firstName,
        lastName: Props.clientInfo.lastName,
        age: ageByDate ? ageByDate : Props.clientInfo.age,
        dealWorth: Props.clientInfo.dealWorth,
        maritalStatus: Props.clientInfo.maritalStatus,
        dependentChildren:
          Props.clientInfo.dependentChildren === 0
            ? ""
            : Props.clientInfo.dependentChildren,
        sourceDescription: Props.clientInfo.sourceDescription,
        occupation: Props.clientInfo.occupation,
        visaDenied: Props.clientInfo.visaDenied,
        deniedText: Props.clientInfo.deniedText,
        visaText: Props.clientInfo.visaText,
        currentVisaTypeId: Props.clientInfo.currentVisaTypeId.toString(),
        currentNewZealandVisaExpiry:
          Props.clientInfo.currentNewZealandVisaExpiry ===
          "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.currentNewZealandVisaExpiry),
        travelConditionsValidTo:
          Props.clientInfo.travelConditionsValidTo ===
          "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.travelConditionsValidTo),
        clientNumber: Props.clientInfo.clientNumber,
        inzUserName: Props.clientInfo.inzUserName,
        inzPassword: Props.clientInfo.inzPassword,
        inzFeeDate:
          Props.clientInfo.inzFeeDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.inzFeeDate),
        nzqaOnlineSubDate:
          Props.clientInfo.nzqaOnlineSubDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.nzqaOnlineSubDate),
        nzqaDocumentSubDate:
          Props.clientInfo.nzqaDocumentSubDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.nzqaDocumentSubDate),
        nzqaDocumentRetDate:
          Props.clientInfo.nzqaDocumentRetDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.nzqaDocumentRetDate),
        email: email,
        secondaryEmail: secondaryEmail,
        otherEmail: otherEmail,
        contactPerson: billAddressData ? billAddressData.contactPerson : "",
        flat: billAddressData ? billAddressData.flat : "",
        streetNumber: billAddressData ? billAddressData.streetNumber : "",
        suburb: billAddressData ? billAddressData.suburb : "",
        city: billAddressData ? billAddressData.city : "",
        billCountry: billAddressData ? billAddressData.country : "",
        zipcode: billAddressData ? billAddressData.zipcode : "",
        clientAddress: clientAddressData ? clientAddressData.address : "",
        mobilePhone: phoneData ? phoneData.mobile : "",
        countryCodeId: phoneData ? phoneData.countryCodeId : "",
        secondaryMobile: phoneData ? phoneData.secondaryMobile : "",
        overseasMobile: phoneData ? phoneData.overseasMobile : "",
        landLine: phoneData ? phoneData.landLine : "",
        otherMobile: phoneData ? phoneData.otherMobile : "",
        medicalIssueDate:
          medicalData &&
          medicalData.medicalIssueDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.medicalIssueDate)
            : "",
        medicalExpiryDate:
          medicalData &&
          medicalData.medicalExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.medicalExpiryDate)
            : "",
        medicalGrading: medicalData ? medicalData.medicalGrading : "",
        xrayIssueDate:
          medicalData &&
          medicalData.xrayIssueDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.xrayIssueDate)
            : "",
        xrayExpiryDate:
          medicalData &&
          medicalData.xrayExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.xrayExpiryDate)
            : "",
        xrayGrading: medicalData ? medicalData.xrayGrading : "",
        medicalNotes: medicalData ? medicalData.medicalNotes : "",
        medicalNotesDetail: medicalData ? medicalData.medicalNotesDetail : "",
        passportNo:
          Props.clientInfo.passports.length > 0
            ? Props.clientInfo.passports[0].passportNo
            : "",
        passportCountry:
          Props.clientInfo.passports.length > 0
            ? Props.clientInfo.passports[0].passportCountry.toString()
            : "",
        passportIssueDate:
          Props.clientInfo.passports.length > 0 &&
          Props.clientInfo.passports[0].passportIssueDate !==
            "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[0].passportIssueDate)
            : "",
        passportExpiryDate:
          Props.clientInfo.passports.length > 0 &&
          Props.clientInfo.passportExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[0].passportExpiryDate)
            : "",
        secondPassportNo:
          Props.clientInfo.passports.length > 1
            ? Props.clientInfo.passports[1].passportNo
            : "",
        secondPassportCountry:
          Props.clientInfo.passports.length > 1
            ? Props.clientInfo.passports[1].passportCountry.toString()
            : "",
        secondPassportIssueDate:
          Props.clientInfo.passports.length > 1 &&
          Props.clientInfo.passportIssueDate !== "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[1].passportIssueDate)
            : "",
        secondPassportExpiryDate:
          Props.clientInfo.passports.length > 1 &&
          Props.clientInfo.passportExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[1].passportExpiryDate)
            : "",
      });
    }
  }, [Props, form]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;
  const country = [];
  const authorityOption = [];
  const visaTypeOption = [];
  if (Props.countriesData && Props.countriesData.items.length > 0) {
    for (let i = 0; i < Props.countriesData.items.length; i++) {
      country.push(
        <Option key={Props.countriesData.items[i].id}>
          {Props.countriesData.items[i].name}
        </Option>
      );
    }
  }

  if (Props.accessingAuthData && Props.accessingAuthData.length > 0) {
    for (let i = 0; i < Props.accessingAuthData.length; i++) {
      authorityOption.push(
        <Option key={Props.accessingAuthData[i].id}>
          {Props.accessingAuthData[i].name}
        </Option>
      );
    }
  }

  if (Props.visaTypeData && Props.visaTypeData.items.length > 0) {
    for (let i = 0; i < Props.visaTypeData.items.length; i++) {
      visaTypeOption.push(
        <Option key={Props.visaTypeData.items[i].id}>
          {Props.visaTypeData.items[i].visaTypeName}
        </Option>
      );
    }
  }

  const handleChange = (selectedOption) => {
    // setGender(selectedOption);
  };

  const handleChangeCertificateCountry = (selectedCountry) => {
    setSelectedCountry(selectedCountry);
  };

  const handleChangePassportCountry = (passportCountry) => {
    // setPassportCountry(passportCountry);
  };

  const onChange = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  };

  const onChangeCertificateIssueDate = (value, dateString) => {
    setCertificateIssueDate(value);
  };

  const onChangeCertificateExpiryDate = (value, dateString) => {
    setCertificateExpiryDate(value);
  };

  const onChangeAuthSubDate = (value, dateString) => {
    setAuthSubDate(value);
  };

  const onChangeAuthRetDate = (value, dateString) => {
    setAuthRetDate(value);
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const changeDeclineValue = (value) => {
    console.log("onOk: ", value);
    setVisaDenied(value);
  };

  const onOkCertificateIssueDate = (value) => {
    console.log("onOk: ", value);
  };

  const addCertificate = () => {
    let data = {
      clientId: Props.clientInfo.id,
      issueDate: certificateIssueDate,
      certificateExpiryDate: certificateExpiryDate,
      country: parseInt(selectedCountry),
    };

    Props.onAddCertificate(data);
  };

  const addAuthority = () => {
    let data = [
      {
        clientId: Props.clientInfo.id,
        authority: 0,
        submittedDate: authSubDate,
        returnedDate: authRetDate,
        refNumber: authRefNo,
      },
    ];

    Props.onAddAuthority(data);
  };

  const removeAuthority = (id) => {
    let data = {
      id: id,
      delete: true,
    };

    Props.onRemoveAuthority(data);
  };

  const onContentChange = (model) => {
    setContent(model);
    Props.onChangeContent(model);
  };

  const onUpdatePersonalInfo = (values) => {
    Props.updatePersonalInfo(values, content);
  };

  return (
    <div>
      {/* <Form form={form} onFinish={onUpdatePersonalInfo}> */}
      <div style={{ paddingTop: 10, paddingLeft: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: 30,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="profile-down-arrow-cont" style={{ marginTop: 5 }}>
              <img
                src={Images.whiteArrow}
                className="profile-down-arrow-icon"
              />
            </div>
            <p className="label">Personal Information</p>
          </div>
          {/* <Form.Item>
              <Button className="button-blue" htmlType="submit">
                <span style={{ color: "#FFFFFF" }}>Update</span>
              </Button>
            </Form.Item> */}
        </div>
      </div>
      <div className="form-container">
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div
            style={{
              width: "100%",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "49%" }}>
                <div>
                  <p>Visa Country</p>
                  <Form.Item name="visaCountryId">
                    <Select showSearch optionFilterProp="children">
                      {Props.brnchVisaCountriesData &&
                        Props.brnchVisaCountriesData.map((data) => {
                          // eslint-disable-next-line react/jsx-no-undef
                          return (
                            <Option value={data.countryId}>
                              {data.countryName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p>Type</p>
                  <Form.Item name="visaCountryType">
                    <Select showSearch optionFilterProp="children">
                      <Option value="1">Student</Option>
                      <Option value="2">Visa </Option>
                      <Option value="3">Unsuccessful</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p>Title</p>
                  <div className="profile-input-border">
                    <Form.Item name="title">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Middle Name</p>
                  <div className="profile-input-border">
                    <Form.Item name="middleName">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Gender</p>
                  <Form.Item name="gender">
                    <Select showSearch optionFilterProp="children">
                      <Option value="1">Male</Option>
                      <Option value="2">Female</Option>
                      <Option value="3">Gender Diverse</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p>Secondary Email</p>
                  <div>
                    <Form.Item
                      name="secondaryEmail"
                      rules={[
                        {
                          type: "email",
                          message: "Invalid!",
                        },
                      ]}
                    >
                      <Input className="profile-input profile-input-border" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Date Of Birth</p>
                  <Form.Item name="dateOfBirth">
                    <DatePicker style={{ width: "100%" }} format={dateFormat} />
                  </Form.Item>
                </div>
                <div>
                  <p>Address</p>
                  <div className="profile-input-border">
                    <Form.Item name="address">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Mobile Phone</p>
                  <div>
                    {/* <Form.Item name="mobilePhone"> */}
                    {/* <Input className="profile-input" /> */}
                    <div className="form-mobile-number">
                      <Form.Item
                        className={"add-client-form-inputs"}
                        name="countryCodeId"
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder={"Select"}
                          style={{
                            width: "150px",
                            minWidth: "150px",
                            marginTop: 12,
                          }}
                          dropdownClassName="dropdown-options-width"
                        >
                          {Props.countriesData &&
                            Props.countriesData.items
                              .filter(
                                (obj) =>
                                  obj.name.toLowerCase() === "new zealand" ||
                                  obj.name.toLowerCase() === "australia" ||
                                  obj.name.toLowerCase() === "canada"
                              )
                              .map((data) => {
                                // eslint-disable-next-line react/jsx-no-undef
                                return (
                                  <Option
                                    value={data.id}
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.name} ( +{data.callingCode} )
                                  </Option>
                                );
                              })}

                          {Props.countriesData &&
                            Props.countriesData.items.map((data, index) => {
                              // eslint-disable-next-line react/jsx-no-undef
                              if (
                                data.name.toLowerCase() === "new zealand" ||
                                data.name.toLowerCase() === "australia" ||
                                data.name.toLowerCase() === "canada"
                              ) {
                              } else {
                                return (
                                  <Option
                                    value={data.id}
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.name} ( +{data.callingCode} )
                                  </Option>
                                );
                              }
                            })}
                        </Select>
                      </Form.Item>
                      <div style={{ width: "100%" }}>
                        <Form.Item
                          className={
                            ("add-client-form-inputs",
                            "mobile-field-margin-left")
                          }
                          name="mobilePhone"
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (Math.ceil(Math.log10(value + 1)) > 15) {
                                  return Promise.reject(
                                    "Character limit exceeded"
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <InputNumber
                          // style={{ width: "288px" }}
                          // onChange={this.onHandleChangeInputNumber}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {/* </Form.Item> */}
                  </div>
                </div>
                <div>
                  <p>Overseas Mobile #</p>
                  <div className="profile-input-border">
                    <Form.Item name="overseasMobile">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Other Contact Information</p>
                  <div className="profile-input-border">
                    <Form.Item name="otherMobile">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Interested Visa</p>
                  <Form.Item name="interestedVisa">
                    <Select showSearch optionFilterProp="children">
                      {visaTypeOption}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p>National ID</p>
                  <div className="profile-input-border">
                    <Form.Item name="nationalityId">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Sale Date</p>
                  <Form.Item name="saleDate">
                    <DatePicker style={{ width: "100%" }} format={dateFormat} />
                  </Form.Item>
                </div>
                <div>
                  <p>Client Source</p>
                  <Form.Item name="sourceId">
                    <Select showSearch optionFilterProp="children">
                      {Props.clientSourceListing &&
                        Props.clientSourceListing.map((listItem) => (
                          <Option key={listItem.id}>{listItem.name}</Option>
                        ))}
                      {/* <Option value="1">Google or other search</Option>
                        <Option value="2">Word of mouth</Option>
                        <Option value="3">Press</Option>
                        <Option value="4">Advertisement</Option>
                        <Option value="5">Article or blog post</Option>
                        <Option value="6">Social media</Option>
                        <Option value="7">Referral</Option>
                        <Option value="8">Other</Option>
                        <Option value="9">Walk In</Option>
                        <Option value="10">Repeat Business</Option>
                        <Option value="11">Recruitment </Option>
                        <Option value="12">Website</Option>
                        <Option value="13">Web Inquiry</Option>
                        <Option value="14">
                          Direct phone call to Immigration Advisor
                        </Option>
                        <Option value="15">Another client</Option>
                        <Option value="16">Employer</Option>
                        <Option value="17">Professional Body</Option> */}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p>Job Sector</p>
                  <Form.Item name="jobSectorId">
                    <Select showSearch optionFilterProp="children">
                      <Option value="1">Administrative</Option>
                      <Option value="2">Agriculture / Farming</Option>
                      <Option value="3">Automotive</Option>
                      <Option value="4">Construction</Option>
                      <Option value="5">Electrical</Option>
                      <Option value="6">Engineer</Option>
                      <Option value="7">Finance</Option>
                      <Option value="8">FMCG</Option>
                      <Option value="9">Hospitality</Option>
                      <Option value="10">Human Resources</Option>
                      <Option value="11">Insurance</Option>
                      <Option value="12">Legal</Option>
                      <Option value="13">Marketing</Option>
                      <Option value="14">Medical</Option>
                      <Option value="15">Real estate</Option>
                      <Option value="16">Retail</Option>
                      <Option value="17">Sales</Option>
                      <Option value="18">Supply chain</Option>
                      <Option value="19">Teachers</Option>
                      <Option value="20">Trades</Option>
                      <Option value="21">Not Employed</Option>
                      <Option value="22">Transportation</Option>
                      <Option value="23">Manufacturing</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p>Company (Optional)</p>
                  <div className="profile-input-border">
                    <Form.Item name="companyOptional">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div style={{ width: "49%" }}>
                <>
                  <div style={{ marginTop: 8 }}>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#556370",
                      }}
                    >
                      Client Reference Number (EZM ID){" "}
                    </span>
                  </div>
                  <div style={{ marginBottom: 16, height: 24 }}>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#255C82",
                      }}
                    >
                      {Props.clientInfo
                        ? Props.clientInfo.clientNumberIZM
                        : " "}
                    </span>
                  </div>
                </>

                <div>
                  <p>Existing Client Serial Number / Internal ID</p>
                  <div className="profile-input-border">
                    <Form.Item name="clientSerial">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Client First Name*</p>
                  <div>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Input className="profile-input profile-input-border" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Last Name</p>
                  <div>
                    <Form.Item
                      name="lastName"
                    >
                      <Input className="profile-input profile-input-border" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Primary Email</p>
                  <div
                    style={{
                      display: "flex",
                      height: 30,
                      paddingRight: 3,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      name="email"
                      className="form-item-email"
                      rules={[
                        {
                          type: "email",
                          message: "Invalid!",
                        },
                      ]}
                    >
                      <Input className="profile-input" />
                    </Form.Item>
                    <div className="profile-inner-emailicon-cont">
                      <img src={Images.emailWhite} className="svg-img" />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Other Email</p>
                  <div className="profile-input-border">
                    <Form.Item
                      name="otherEmail"
                      rules={[
                        {
                          type: "email",
                          message: "Invalid!",
                        },
                      ]}
                    >
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Age</p>
                  <div className="profile-input-border">
                    <Form.Item name="age">
                      <Input disabled className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Deal Worth</p>
                  <div className="profile-input-border">
                    <Form.Item name="dealWorth">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Secondary Mobile</p>
                  <div className="profile-input-border">
                    <Form.Item name="secondaryMobile">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Land Line</p>
                  <div className="profile-input-border">
                    <Form.Item name="landLine">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Marital Status</p>
                  <Form.Item name="maritalStatus">
                    <Select showSearch optionFilterProp="children">
                      <Option value="single">Single</Option>
                      <Option value="married">Married</Option>
                      <Option value="widowed">Widowed</Option>
                      <Option value="defacto">Defacto</Option>
                      <Option value="separated">Separated</Option>
                      <Option value="divorced">Divorced </Option>
                      <Option value="partner">Partner </Option>
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p>Number Of Dependent Children</p>
                  <div className="profile-input-border">
                    <Form.Item name="dependentChildren">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Nationality</p>
                  <div>
                    <Form.Item name="nationalityCountry">
                      <Select showSearch optionFilterProp="children">
                        {Props.countriesData &&
                          Props.countriesData.items
                            .filter(
                              (obj) =>
                                obj.name.toLowerCase() === "new zealand" ||
                                obj.name.toLowerCase() === "australia" ||
                                obj.name.toLowerCase() === "canada"
                            )
                            .map((data) => {
                              // eslint-disable-next-line react/jsx-no-undef
                              return (
                                <Option value={data.id}>{data.name}</Option>
                              );
                            })}

                        {Props.countriesData &&
                          Props.countriesData.items.map((data, index) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            if (
                              data.name.toLowerCase() === "new zealand" ||
                              data.name.toLowerCase() === "australia" ||
                              data.name.toLowerCase() === "canada"
                            ) {
                            } else {
                              return (
                                <Option value={data.id}>{data.name}</Option>
                              );
                            }
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Sales Person</p>
                  <Form.Item name="agentId">
                    <Select showSearch optionFilterProp="children">
                      {Props &&
                        Props.users.map((person) => (
                          <Option key={person.id}>{person.fullName}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p>Source Description</p>
                  <div className="profile-input-border">
                    <Form.Item name="sourceDescription">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p>Occupation</p>
                  <div className="profile-input-border">
                    <Form.Item name="occupation">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>Notes</p>
          <Spin size={"large"} spinning={loading}>
            <FroalaEditorCom
              setLoading={(value) => setLoading(value)}
              model={content}
              onModelChange={onContentChange}
            />
          </Spin>
        </div>
      </div>

      <div
        className="denied-cont"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div className="denied-cont" style={{ padding: 0 }}>
          <div className="profile-down-arrow-cont">
            <img src={Images.whiteArrow} className="profile-down-arrow-icon" />
          </div>
          <span className="denied-text">Have You Ever Been Denied a Visa</span>
        </div>
      </div>
      <div className="form-container">
        <div
          style={{
            paddingTop: 10,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <Form.Item name="visaDenied">
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <span style={{ marginLeft: 0 }} className="detailed-text">
            Decline Detail
          </span>
          <div className="profile-input-border">
            <Form.Item name="deniedText">
              <TextArea rows={4} className="profile-input" />
            </Form.Item>
          </div>
        </div>
      </div>
      {/* </Form> */}

      {/*<div className="denied-cont">
        <div className="profile-down-arrow-cont">
          <img
            src={Images.whiteArrow}
            className="profile-down-arrow-icon"
          />
        </div>
        <span className="denied-text">Applicants</span>
      </div>
      <div className="form-container">
        <div
          className="form-cont"
          style={{
            justifyContent: "space-between",
            paddingBottom: 0
          }}
        >
          <div className="input-cont-width">
            <p>Ref #</p>
            <Form.Item>
              <div className="profile-input-border">
                <input
                  className="profile-input"
                  placeholder=""
                  type="text"
                  onChange={e => console.log(e)}
                />
              </div>
            </Form.Item>
          </div>
          <div className="input-cont-width">
            <p>Authority</p>
            <Form.Item>
              <Select
                value={selectedOption}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
        </div>

        <div className="button-org-cont">
          <div className="button-org">
            <span style={{ color: "#FFFFFF" }}>ADD</span>
          </div>
        </div>

        <div
          className="visa-table"
          style={{ paddingBottom: 0, paddingTop: 10 }}
        >
          <div
            className="main-table-cont"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="visa-table-header">
              <div className="header-tab-10">
                <span className="authority-table-head-font">Sr #</span>
              </div>
              <div className="header-tab-40">
                <span className="authority-table-head-font">Name</span>
              </div>
              <div className="header-tab-20">
                <span className="authority-table-head-font">Visa</span>
              </div>
              <div className="header-tab-20">
                <span className="authority-table-head-font">
                  Actions
                </span>
              </div>
            </div>
            <div className="table-content">
              <div className="content-index-10">
                <span className="auth-text">111356</span>
              </div>
              <div className="content-index-40">
                <span className="auth-text">Student Visa - sec 61</span>
              </div>
              <div className="content-index-20">
                <span className="auth-text"></span>
              </div>
              <div className="content-index-15">
                <div className="remove-cont">
                  <img
                    src={Images.cgreenCross}
                    style={{ width: 7, height: 7 }}
                  />
                  <span className="remove-text">REMOVE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}

      {/*<div className="denied-cont">
        <div className="profile-down-arrow-cont">
          <img
            src={Images.whiteArrow}
            className="profile-down-arrow-icon"
          />
        </div>
        <span className="denied-text">Client Agreement</span>
      </div>
      <div className="form-container">
        <div
          className="form-cont"
          style={{
            paddingBottom: 0,
            justifyContent: "space-between"
          }}
        >
          <div className="input-cont-width">
            <p>Agreement</p>
            <Form.Item>
              <Select
                value={selectedOption}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 10 }}>
            <div
              className="black-button"
              style={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <span
                className="black-button-text"
                style={{ fontSize: 8 }}
              >
                Ready Contract
              </span>
            </div>
            <div className="cross-cont" style={{ width: 70 }}>
              <img src={Images.btnImage} className="profile-btn-img" />
              <img src={Images.crossWhite} className="svg-btn-img" />
            </div>
          </div>
        </div>
        <div
          className="form-cont"
          style={{
            justifyContent: "space-between",
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          <div className="input-cont-width">
            <p>Advisor</p>
            <Form.Item>
              <Select
                value={selectedOption}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div
            className="button-blue-cont"
            style={{ marginTop: 34, paddingRight: 0, width: 70 }}
          >
            <div className="button-blue">
              <span style={{ color: "#FFFFFF" }}>Save</span>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
}

export default PersonalInformation;
