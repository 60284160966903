import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";
import { message } from "antd";

export const invoicesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_INVOICE_LISTING_SUCCESS:
      return {
        ...state,
        invoiceListing: action.payload,
        invoicesCount: action.payload.totalCount,
      };
    case types.GET_INVOICE_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Invoices list is empty!");
        return { ...state, invoiceListing: [], invoicesCount: 0 };
      } else {
      }
      console.log("on error console", action.payload);
    case types.GET_INVOICE_LISTING_BY_SUBJECT_ID_SUCCESS:
      return {
        ...state,
        invoiceListingBySubjectId: action.payload,
        xeroInvoicesCount: action.payload.totalCount,
      };
    case types.GET_INVOICE_LISTING_BY_SUBJECT_ID_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Invoices list is empty!");
        return {
          ...state,
          invoiceListingBySubjectId: [],
          xeroInvoicesCount: 0,
        };
      } else {
        // openNotificationWithIcon("error", "Error", action.payload.message);
      }
      console.log("on error console", action.payload);
    case types.GET_ALL_INVOICE_TYPES_SUCCESS:
      return { ...state, invoiceTypesListing: action.payload };
    case types.GET_ALL_INVOICE_TYPES_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_ALL_BANKS_LISTING_SUCCESS:
      return { ...state, bankListing: action.payload };
    case types.GET_ALL_BANKS_LISTING_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_BANK_INFO_SUCCESS:
      return { ...state, bankInfo: action.payload };
    case types.GET_ALL_TAXES_LISTING_SUCCESS:
      return { ...state, taxListing: action.payload };
    case types.GET_ALL_TAXES_LISTING_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_TAX_INFO_SUCCESS:
      return { ...state, taxInfo: action.payload };
    case types.GET_ALL_NOTES_LISTING_SUCCESS:
      return { ...state, noteListing: action.payload };
    case types.GET_ALL_NOTES_LISTING_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_NOTE_INFO_SUCCESS:
      return { ...state, noteInfo: action.payload };
    case types.GET_ALL_CURRENCY_LISTING_SUCCESS:
      return { ...state, currencyListing: action.payload };
    case types.GET_ALL_CURRENCY_LISTING_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_LAST_INVOICE_NO_SUCCESS:
      return { ...state, lastInvoiceNumber: action.payload };
    case types.GET_LAST_INVOICE_NO_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_INVOICE_INFO_SUCCESS:
      return { ...state, invoiceInfo: action.payload };
    case types.GET_INVOICE_INFO_FAILURE:
      console.log("on error console", action.payload);
    case types.SET_EMAIL_TEMPLATE_PAYMENT_SUCCESS:
      return { ...state, setEmailData: action.payload };
    case types.SET_EMAIL_TEMPLATE_PAYMENT_FAILURE:
      return { ...state, setEmailError: action.payload };
    case types.SET_INVOICES_DATA_NULL_SUCCESS:
      return { ...state, invoiceInfo: null };
    case types.SET_SCHOOL_INVOICES_DATA_NULL_SUCCESS:
      return { ...state, invoiceSchoolInfo: null };
    case types.GET_INVOICE_STATUSES_LISTING_SUCCESS:
      return { ...state, invoiceStatuses: action.payload };
    case types.GET_INVOICE_STATUSES_LISTING_FAILURE:

    case types.CHECK_DUPLICATE_INVOICE_SUCCESS:
      return { ...state, duplicate: action.payload };
    case types.CHECK_DUPLICATE_INVOICE_FAILURE:

    case types.GET_ALL_INVOICE_PAYMENTS_SUCCESS:
      return { ...state, invoicePayments: action.payload };
    case types.GET_ALL_INVOICE_PAYMENTS_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Payment list is empty!");
        return { ...state, invoicePayments: [] };
      } else {
      }
      console.log("on error console", action.payload);
    case types.GET_SCHOOL_INVOICE_INFO_SUCCESS:
      return {
        ...state,
        invoiceSchoolInfo: action.payload,
        invoicePayments: [],
      };
    case types.GET_SCHOOL_EMAIL_SUCCESS:
      return {
        ...state,
        selectedSchoolEmail: action.payload,
        selectedSchoolId: action.id,
      };
    case types.GET_SCHOOL_INVOICE_INFO_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_INVOICE_TEMPLATE_SUCCESS:
      return {
        ...state,
        invoiceTempRes: action.payload,
      };
    case types.GET_INVOICE_TEMPLATE_FAILURE:
      console.log("on error console", action.payload);
    default:
      return state;
  }
};
